import __request__ from 'routing/request';
interface HubReleaseNotes {
    readonly release_notes: string;
    readonly version: string;
}
interface HubLatestVersionResponse {
    readonly required_gl_version: unknown[];
    readonly is_new_version: boolean;
    readonly version: string;
}
interface HubListResponse {
    readonly total: number;
    readonly bundles: HubBundleResponse[];
}
interface HubBundleResponse {
    readonly minimum_graylog_version: string[];
    readonly installed: boolean;
    readonly size_bytes: number;
    readonly prerelease: boolean;
    readonly is_compatible: boolean;
    readonly title: string;
    readonly published_date: string;
    readonly version: string;
}
/**
 * List all available Illuminate versions on Illuminate Hub.
 */
export function listBundles(): Promise<HubListResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.illuminate/bundles/hub', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get the latest bundle version and check if it is currently installed.
 */
export function getLatestVersion(): Promise<HubLatestVersionResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.illuminate/bundles/hub/latest', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve release notes for a specific Illuminate version.
 */
export function getReleaseNotes(version: string): Promise<HubReleaseNotes> {
    return __request__('GET', `/plugins/org.graylog.plugins.illuminate/bundles/hub/release_notes/${version}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Download a bundle from Illuminate Hub and install it.
 */
export function download(version: string): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.illuminate/bundles/hub/${version}`, null, {}, {
        'Accept': ['application/json']
    });
}
