import * as React from 'react';

import { MenuItem } from 'components/bootstrap';
import type { ModalHandler } from 'security-app/components/Investigations/ModalHandler.type';
import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';
import { useAddEvidence, useGetInvestigationDetails } from 'security-app/hooks/useInvestigationsAPI';
import useActionCondition from 'security-app/components/Investigations/plugin/useActionCondition';

import AddEvidenceModal from './shared/AddEvidenceModal';

type Props = {
  eventId: string,
  modalRef: () => ModalHandler,
};

export const EventsInvestigationsModal = React.forwardRef(({ eventId }: { eventId: string }, ref: (r: ModalHandler) => void) => {
  const isAvailable = useActionCondition();

  if (!isAvailable) {
    return null;
  }

  return (
    <AddEvidenceModal ids={[eventId]} type="events" ref={ref} />
  );
});

const CoreEventWidgetActions = ({ eventId, modalRef }: Props) => {
  const { selectedInvestigationId, setCollapsed } = useInvestigationDrawer();
  const { currentInvestigation } = useGetInvestigationDetails(selectedInvestigationId);

  const { addEvidence } = useAddEvidence();

  const disabledAdd = !currentInvestigation || currentInvestigation?.archived;

  const onAddEvent = async () => {
    if (disabledAdd) return;

    await addEvidence({
      invId: selectedInvestigationId,
      payload: { events: [eventId] },
    });

    setCollapsed(false);
  };

  return (
    <>
      <MenuItem disabled={disabledAdd} onClick={onAddEvent} icon="extension">
        Add to investigation
      </MenuItem>
      <MenuItem icon="extension" onClick={() => modalRef()?.toggle()}>
        Select an investigation
      </MenuItem>
    </>
  );
};

export default CoreEventWidgetActions;
