import __request__ from 'routing/request';
interface AuditLogEntriesSummary {
    readonly total: number;
    readonly entries: AuditLogEntryView[];
    readonly perPage: number;
    readonly query: string;
    readonly count: number;
    readonly page: number;
}
interface SearchQueryRequest {
    readonly per_page: number;
    readonly query: string;
    readonly page: number;
}
interface AuditLogEntryView {
    readonly actor: string;
    readonly actor_formatted: string;
    readonly message_template: string;
    readonly namespace: string;
    readonly action: string;
    readonly attributes: {
        readonly [_key: string]: Object;
    };
    readonly success_status: 'SUCCESS' | 'FAILURE';
    readonly id: string;
    readonly message: string;
    readonly node_id: string;
    readonly object: string;
    readonly timestamp: string;
}
/**
 * Get audit log entries
 */
export function entries(page: number = 1, per_page: number = 50): Promise<AuditLogEntriesSummary> {
    return __request__('GET', '/plugins/org.graylog.plugins.auditlog/entries', null, { 'page': page, 'per_page': per_page }, {
        'Accept': ['application/json']
    });
}
/**
 * Search audit log entries
 */
export function search(query?: SearchQueryRequest): Promise<AuditLogEntriesSummary> {
    return __request__('POST', '/plugins/org.graylog.plugins.auditlog/entries', query, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Export audit log entries as CSV
 */
export function exportCsv(query?: string, limit?: number): Promise<unknown> {
    return __request__('GET', '/plugins/org.graylog.plugins.auditlog/entries/export/csv', null, { 'query': query, 'limit': limit }, {
        'Accept': ['text/csv']
    });
}
/**
 * Export audit log entries as JSON
 */
export function exportJson(query?: string, limit?: number): Promise<unknown> {
    return __request__('GET', '/plugins/org.graylog.plugins.auditlog/entries/export/json', null, { 'query': query, 'limit': limit }, {
        'Accept': ['application/json']
    });
}
