import __request__ from 'routing/request';
interface Binding {
    readonly type: string;
}
interface TimeRange {
    readonly type: string;
}
interface QueryMetadata {
    readonly used_parameters_names: string[];
}
interface UsedSearchFilter {
}
interface Filter {
    readonly filters: Filter[];
    readonly type: string;
}
interface QueryDTO {
    readonly filter: Filter;
    readonly timerange: TimeRange;
    readonly query: BackendQuery;
    readonly id: string;
    readonly filters: UsedSearchFilter[];
    readonly search_types: SearchType[];
}
interface SearchDTO {
    readonly skip_no_streams_check: boolean;
    readonly id: string;
    readonly parameters: Parameter[];
    readonly queries: QueryDTO[];
}
interface Parameter {
    readonly name: string;
    readonly data_type: string;
    readonly binding: Binding;
    readonly description: string;
    readonly optional: boolean;
    readonly default_value: Object;
    readonly title: string;
    readonly type: string;
}
interface SearchMetadata {
    readonly declared_parameters: {
        readonly [_key: string]: Parameter;
    };
    readonly query_metadata: {
        readonly [_key: string]: QueryMetadata;
    };
}
interface SearchType {
    readonly stream_categories: string[];
    readonly filter: Filter;
    readonly timerange: unknown;
    readonly query: BackendQuery;
    readonly streams: string[];
    readonly name: string;
    readonly id: string;
    readonly filters: UsedSearchFilter[];
    readonly type: string;
}
interface BackendQuery {
}
/**
 * Metadata for the posted Search object
 */
export function metadataForObject(arg0?: SearchDTO): Promise<SearchMetadata> {
    return __request__('POST', '/plugins/org.graylog.plugins.views/views/search/metadata', arg0, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Metadata for the given Search object
 * @param searchId searchId
 */
export function metadata(searchId?: string): Promise<SearchMetadata> {
    return __request__('GET', `/plugins/org.graylog.plugins.views/views/search/metadata/${searchId}`, null, {}, {
        'Accept': ['application/json']
    });
}
