import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { useSelectedRows, useSelectedRowsDispatch } from 'common/contexts';
import type { Pack } from 'illuminate/types';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { Spinner } from 'components/common';
import { Button, ControlLabel } from 'components/bootstrap';
import { BundleContext } from 'illuminate/context/BundleContext';
import { useUpdatePacks } from 'illuminate/hooks/usePacksAPI';
import { GLCheckbox } from 'common/components';
import { getRequirements, getDependents } from 'illuminate/utils';
import IlluminatePackRequirement from 'illuminate/components/illuminate/IlluminatePackRequirement';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

type Props = {
  packs: Pack[],
}

const StyledControlLabel = styled(ControlLabel)`
  display: flex;
  align-items: center;
`;

const BulkActionsWrap = styled.div(({ theme }) => css`
  padding: ${theme.spacings.sm} 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  div > button {
    margin: 0 ${theme.spacings.xs};
  }
`);

const SelectedPacksInfo = styled.span(({ theme }) => css`
  margin-left: ${theme.spacings.xs};
`);

const IlluminateBulkActions = ({ packs }: Props) => {
  const { bundleVersion } = useContext(BundleContext);
  const { updatePacks, updatingPacks } = useUpdatePacks();
  const sendTelemetry = useSendTelemetry();

  const selectedPacks = useSelectedRows();
  const selectedPacksDispatch = useSelectedRowsDispatch();

  const [showConfirmEnable, setShowConfirmEnable] = useState<boolean>(false);
  const [showConfirmDisable, setShowConfirmDisable] = useState<boolean>(false);

  const selectedPacksNames = useMemo(() => {
    if (selectedPacks) {
      return selectedPacks.map((pack: Pack) => pack.title);
    }

    return '';
  }, [selectedPacks]);

  const formattedPackNames = useMemo(() => {
    const packNames = [...selectedPacksNames];
    const lastPack = packNames.pop();

    return packNames.length
      ? `${packNames.join(', ')} & ${lastPack}`
      : lastPack;
  }, [selectedPacksNames]);

  const selectAll = (e: React.BaseSyntheticEvent) => {
    if (e.target.checked) {
      selectedPacksDispatch({ type: 'add', payload: packs });
    } else {
      selectedPacksDispatch({ type: 'remove', payload: packs });
    }
  };

  const toggleConfirmEnable = useCallback(() => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.ILLUMINATE.PACK_ENABLE_MODAL_TOGGLED, {
      app_pathname: 'illuminate',
      app_section: 'packs',
      event_details: {
        illuminate_enterprise: true,
        illuminate_version: bundleVersion,
      },
    });

    setShowConfirmEnable(!showConfirmEnable);
  }, [bundleVersion, sendTelemetry, showConfirmEnable]);

  const toggleConfirmDisable = useCallback(() => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.ILLUMINATE.PACK_DISABLE_MODAL_TOGGLED, {
      app_pathname: 'illuminate',
      app_section: 'packs',
      event_details: {
        illuminate_enterprise: true,
        illuminate_version: bundleVersion,
      },
    });

    setShowConfirmDisable(!showConfirmDisable);
  }, [bundleVersion, sendTelemetry, showConfirmDisable]);

  const dialogToggleConfirm = useMemo(() => (
    showConfirmEnable ? toggleConfirmEnable : toggleConfirmDisable
  ), [showConfirmEnable, toggleConfirmEnable, toggleConfirmDisable]);

  const handleConfirm = async () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.ILLUMINATE.PACKS_BULK_UPDATED, {
      app_pathname: 'illuminate',
      app_section: 'packs',
      event_details: {
        illuminate_enterprise: true,
        illuminate_version: bundleVersion,
      },
    });

    const requestObject = {
      enabled: showConfirmEnable,
      processingPackIds: [],
      spotlightPackIds: [],
    };

    selectedPacks.forEach((pack: Pack) => {
      if (pack.type === 'PROCESSING') {
        requestObject.processingPackIds.push(pack.pack_id);
      } else if (pack.type === 'SPOTLIGHT') {
        requestObject.spotlightPackIds.push(pack.pack_id);
      }
    });

    await updatePacks({ bundleVersion, requestObject });
    dialogToggleConfirm();
    selectedPacksDispatch({ type: 'clear' });
  };

  const getModalRequirements = (selectedPack: Pack): Pack[] => {
    let packList = [];

    if (showConfirmEnable) {
      packList = getRequirements({
        selectedPack,
        packs,
      }).filter((req) => !req.enabled && selectedPacksNames.indexOf(req.title) === -1);
    } else {
      packList = getDependents({
        selectedPack,
        packs,
      }).filter((req) => req.enabled && selectedPacksNames.indexOf(req.title) === -1);
    }

    if (packList.length === 0) {
      return null;
    }

    return packList;
  };

  const actionText = showConfirmEnable ? 'Enabling' : 'Disabling';

  const allSelected = React.useMemo(() => (
    packs.length > 0
    && selectedPacks?.length > 0
    && packs.every((pack: Pack) => (
      !!selectedPacks.find((sPack: Pack) => pack.pack_id === sPack.pack_id)
    ))
  ), [packs, selectedPacks]);

  return (
    <>
      <BulkActionsWrap>
        <span>
          <StyledControlLabel htmlFor="illuminate-bulk">
            <GLCheckbox data-testid="select-all"
                        name="illuminate-bulk"
                        id="illuminate-bulk"
                        onChange={selectAll}
                        checked={allSelected} />
            <SelectedPacksInfo>{selectedPacks ? selectedPacks.length : '0'} Selected</SelectedPacksInfo>
          </StyledControlLabel>
        </span>

        <div>
          <Button bsStyle="primary"
                  bsSize="xsmall"
                  onClick={toggleConfirmEnable}
                  disabled={selectedPacksNames.length === 0}>
            Enable Selected
          </Button>

          <Button bsStyle="warning"
                  bsSize="xsmall"
                  onClick={toggleConfirmDisable}
                  disabled={selectedPacksNames.length === 0}>
            Disable Selected
          </Button>
        </div>
      </BulkActionsWrap>

      <ConfirmDialog show={showConfirmEnable || showConfirmDisable}
                     onConfirm={handleConfirm}
                     btnConfirmText={updatingPacks ? <Spinner text={`${actionText}...`} delay={0} /> : 'Confirm'}
                     btnConfirmDisabled={updatingPacks}
                     onCancel={dialogToggleConfirm}
                     title={`${actionText} Illuminate Pack`}>
        <>
          <p>You are about to {showConfirmEnable ? 'enable' : 'disable'} <strong>{formattedPackNames}</strong>.</p>
          <p>This could cause potential {showConfirmEnable ? 'creation' : 'loss'} of data and will pause all processing
            for a few seconds.
          </p>
          <p>Do you wish to continue?</p>
          {selectedPacks && selectedPacks.map((selectedPack: Pack) => {
            const modalRequirements = getModalRequirements(selectedPack);

            if (!modalRequirements) {
              return null;
            }

            return (
              <div key={selectedPack.pack_id}>
                <b>{selectedPack.title} {showConfirmEnable ? 'depends on:' : 'is required by:'}</b>
                <IlluminatePackRequirement requiredPacks={getModalRequirements(selectedPack)} />
              </div>
            );
          })}
        </>
      </ConfirmDialog>
    </>
  );
};

export default IlluminateBulkActions;
