import { useQuery } from '@tanstack/react-query';

import FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import { DataWarehouse } from '@graylog/enterprise-api';

type DataWarehouseField = {
  field_name: string,
  class: string
}

const mapFieldType = (fieldType: string) => {
  switch (fieldType) {
    case 'java.util.Collection':
      return 'list<string>';
    case 'java.lang.String':
      return 'string';
    default:
      return 'unknown';
  }
};

const deserializeFieldTypes = (fieldTypes: Array<DataWarehouseField>) => fieldTypes.map(({ field_name: fieldName, class: fieldType }) => FieldTypeMapping.fromJSON({
  name: fieldName,
  type: {
    type: mapFieldType(fieldType),
    properties: [],
    index_names: [],
  },
}));

const useFieldTypes = (): { data: Array<FieldTypeMapping>, refetch: () => void, isLoading?: boolean, isFetching?: boolean } => useQuery(
  ['data-warehouse', 'field-types'],
  () => DataWarehouse.filterableFields().then(deserializeFieldTypes),
  { staleTime: 30000 },
);

export default useFieldTypes;
