import __request__ from 'routing/request';
interface ClusterReadinessChecks {
    readonly ready: boolean;
    readonly details: Details;
}
interface Repository {
    readonly type: string;
}
interface Details {
    readonly supported_by_search_version: boolean;
    readonly supported_repository_types: string[];
    readonly restored_snapshots_assignable: boolean;
}
type RepositoryStateArray = RepositoryState[];
type stringArray = string[];
interface RepositoryRequest {
    readonly type: string;
}
interface RepositoryState {
    readonly ready: boolean;
    readonly details: string;
    readonly repository: Repository;
}
/**
 * Get all warm tier repositories.
 */
export function allRepositories(): Promise<RepositoryStateArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.datatiering/datatiering/repositories', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create warm tier repository
 */
export function save(repository: RepositoryRequest): Promise<Repository> {
    return __request__('POST', '/plugins/org.graylog.plugins.datatiering/datatiering/repositories', repository, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all file system repository paths.
 */
export function allFsRepoPaths(): Promise<stringArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.datatiering/datatiering/repositories/fs-paths', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete failed snapshots for index set
 */
export function deleteFailedSnapshot(id: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.datatiering/datatiering/repositories/index_set/${id}/failed_snapshot`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Check if the search cluster is ready to handle warm indices
 */
export function getReadinessChecks(): Promise<ClusterReadinessChecks> {
    return __request__('GET', '/plugins/org.graylog.plugins.datatiering/datatiering/repositories/readiness', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update warm tier repository
 */
export function update(repository: RepositoryRequest, name: string): Promise<Repository> {
    return __request__('PUT', `/plugins/org.graylog.plugins.datatiering/datatiering/repositories/${name}`, repository, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete warm tier repository
 */
export function remove(name: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.datatiering/datatiering/repositories/${name}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Repair warm tier repository
 */
export function repair(name: string): Promise<void> {
    return __request__('PUT', `/plugins/org.graylog.plugins.datatiering/datatiering/repositories/${name}/repair`, null, {}, {
        'Accept': ['application/json']
    });
}
