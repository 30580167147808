import * as React from 'react';

import { EmptyList, TD, RowTitle } from 'security-app/components/common';
import { GLCheckbox } from 'common/components';
import type { OwnerOptionType } from 'security-app/hooks/api/securityEventsAPI.types';
import type { ColumnFilterData, FilterData } from 'security-app/components/common/Filters/ColumnFilter.types';

type Props = {
  ownerOptions: OwnerOptionType[],
  filters: ColumnFilterData,
  ownerId: string,
  setOwnerId: (owner: string) => void,
};

function ListRows({ ownerOptions, filters, ownerId, setOwnerId }: Props) {
  const selectOwner = (newOwner: OwnerOptionType) => () => {
    setOwnerId(newOwner.id);
  };

  const strFilters = Object.entries(filters).reduce((acc: { [key: string]: string }, [key, values]: [string, FilterData[]]) => ({
    ...acc, [key]: values.join(','),
  }), {});

  return ownerOptions.length < 1 ? (
    <EmptyList filters={strFilters}
               noSearchResultText="No User or Teams found for the provided criteria"
               noEntitiesExist="Currently there are no User or Teams to list"
               colSpan={3} />
  ) : (
    <>
      {ownerOptions.map((owner: OwnerOptionType) => (
        <tr key={owner.id}>
          <TD><GLCheckbox checked={owner.id === ownerId} onChange={selectOwner(owner)} /></TD>
          <TD>
            <RowTitle onClick={selectOwner(owner)}>
              {owner.name}
            </RowTitle>
          </TD>
          <TD>{owner.type}</TD>
        </tr>
      ))}
    </>
  );
}

export default ListRows;
