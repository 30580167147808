import * as React from 'react';
import { useMemo, useCallback, useState } from 'react';
import Immutable from 'immutable';

import type { TimeRange } from 'views/logic/queries/Query';
import type { Message } from 'views/components/messagelist/Types';
import type { FieldTypeMappingsList } from 'views/logic/fieldtypes/types';
import LogViewWidget from 'logview/components/LogViewWidget';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import WidgetFrame from 'views/components/widgets/WidgetFrame';
import { WidgetHeader } from 'views/components/widgets';
import WidgetContainer from 'views/components/WidgetContainer';
import { fetchSearchResults, PAGE_SIZE } from 'data-warehouse/search/useSearchResults';
import type { LogViewMessage } from 'logview/types';
import { IconButton } from 'components/common';
import EditWidgetFrame from 'views/components/widgets/EditWidgetFrame';
import WidgetContext from 'views/components/contexts/WidgetContext';
import type DataWarehouseWidget from 'data-warehouse/logic/DataWarehouseWidget';
import DataWarehouseLogViewEdit from 'data-warehouse/search/DataWarehouseLogViewEdit';
import type DataWarehouseWidgetConfig from 'data-warehouse/logic/DataWarehouseWIdgetConfig';

type EditWrapperProps = React.PropsWithChildren<{
  config: DataWarehouseWidgetConfig,
  editing: boolean,
  onChangeWidgetConfig: (config: DataWarehouseWidgetConfig) => Promise<void>,
  onSubmit: (newWidget: DataWarehouseWidget, hasChanges: boolean) => Promise<void>,
  onToggleEdit: () => void,
}>

const EditWrapper = ({ onToggleEdit, editing, children, onSubmit, config, onChangeWidgetConfig }: EditWrapperProps) => {
  if (!editing) {
    return children;
  }

  return (
    <EditWidgetFrame onCancel={onToggleEdit}
                     displaySubmitActions={false}
                     onSubmit={onSubmit}
                     containerComponent={React.Fragment}
                     showQueryControls={false}>
      <DataWarehouseLogViewEdit config={config} onCancel={onToggleEdit} onChange={onChangeWidgetConfig}>
        {children}
      </DataWarehouseLogViewEdit>
    </EditWidgetFrame>
  );
};

const messageListAfter = (messages: Array<LogViewMessage>) => {
  if (messages?.length === 0 || messages?.length < PAGE_SIZE) {
    return undefined;
  }

  const lastMessage = messages[0];

  return ({
    timestamp: lastMessage.message.timestamp,
    id: lastMessage.message._id,
  });
};

type Props = {
  editing: boolean,
  searchRequestParams: {
    fields: Array<string>,
    filters: { stream: string, timerange: TimeRange, queryString: string },
  },
  fields: FieldTypeMappingsList,
  messages: Array<LogViewMessage>,
  onChangeWidget: (newWidget: DataWarehouseWidget) => Promise<void>
  onToggleEdit: () => void,
  widget: DataWarehouseWidget
};

const DWLogViewWidget = ({
  messages, onToggleEdit, widget, onChangeWidget,
  editing, fields, searchRequestParams,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const fieldTypes = useMemo(() => ({ all: fields ?? Immutable.List(), queryFields: Immutable.Map<string, FieldTypeMappingsList>() }), [fields]);
  const initialAfter = useMemo(() => messageListAfter(messages), [messages]);
  const onLoadMessages = useCallback(async (searchAfter: { timestamp: string, id: string }) => {
    const prevMessages = await fetchSearchResults({ ...searchRequestParams.filters, fields: searchRequestParams.fields, searchAfter });

    return {
      messages: prevMessages,
      after: messageListAfter(prevMessages),
    };
  }, [searchRequestParams.fields, searchRequestParams.filters]);
  const onLoadMessage = useCallback((message: { message: { [fieldName: string]: unknown, }}) => (
    Promise.resolve({ formatted_fields: message.message, filtered_fields: message.message, fields: message.message } as unknown as Message)
  ), []);

  const onChangeConfig = useCallback((newConfig: DataWarehouseWidgetConfig) => (
    onChangeWidget(widget.toBuilder().config(newConfig).build())
  ), [onChangeWidget, widget]);

  const onSubmitEdit = useCallback((newWidget: DataWarehouseWidget, hasChanges: boolean) => {
    if (hasChanges) {
      return onChangeWidget(newWidget).then(() => onToggleEdit());
    }

    onToggleEdit();

    return Promise.resolve();
  }, [onChangeWidget, onToggleEdit]);

  return (
    <WidgetContext.Provider value={widget}>
      <FieldTypesContext.Provider value={fieldTypes}>
        <WidgetContainer className="widgetFrame" isFocused>
          <WidgetFrame widgetId="data-wearhouse-log-view-widget">
            <WidgetHeader title="Log view"
                          hideDragHandle
                          loading={loading}
                          editing={editing}>
              {!editing && (
                <IconButton name="edit_square"
                            title="Edit"
                            iconType="regular"
                            onClick={onToggleEdit} />
              )}
            </WidgetHeader>
            <EditWrapper onToggleEdit={onToggleEdit} editing={editing} onSubmit={onSubmitEdit} config={widget.config} onChangeWidgetConfig={onChangeConfig}>
              <LogViewWidget config={widget.config}
                             initialAfter={initialAfter}
                             enableMessageDetails={false}
                             messages={messages}
                             onLoadMessages={onLoadMessages}
                             editing={editing}
                             onLoadMessage={onLoadMessage}
                             setLoadingState={setLoading}
                             fields={fields}
                             onChangeConfig={onChangeConfig} />
            </EditWrapper>
          </WidgetFrame>
        </WidgetContainer>
      </FieldTypesContext.Provider>
    </WidgetContext.Provider>
  );
};

export default DWLogViewWidget;
