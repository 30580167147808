import __request__ from 'routing/request';
interface AssetImportBackendConfig {
    readonly type: string;
}
interface AssetImportBackendRequest {
    readonly scheduled_config: ScheduledConfigView;
    readonly description: string;
    readonly priority: number;
    readonly category: string[];
    readonly title: string;
    readonly config: AssetImportBackendConfig;
}
interface ScheduledConfigView {
    readonly enabled: boolean;
    readonly interval_hours: number;
}
interface PaginatedResponse_AssetImportBackendView {
    readonly [_key: string]: Object;
}
/**
 * Returns the asset import backends for the given asset source
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function get(sourceId: string, sort: 'title' = 'title', page: number = 1, per_page: number = 15, query?: string, filters?: string, direction: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_AssetImportBackendView> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}/import-backends`, null, { 'page': page, 'per_page': per_page, 'query': query, 'filters': filters, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Creates a new asset import backend configuration for the given asset source
 */
export function create(sourceId: string, JSONbody: AssetImportBackendRequest): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}/import-backends`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single existing asset import backend
 */
export function getBackend(sourceId: string, importId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}/import-backends/${importId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Updates the asset import backend configuration for the given asset source
 */
export function update(sourceId: string, importId: string, JSONbody: AssetImportBackendRequest): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}/import-backends/${importId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Deletes the asset import backend configuration for the given asset source
 */
export function remove(sourceId: string, importId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}/import-backends/${importId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Schedule asset sync for an existing import backend
 */
export function scheduleImport(sourceId: string, importId: string, JSONbody: ScheduledConfigView): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}/import-backends/${importId}/schedule`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
