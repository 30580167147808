import __request__ from 'routing/request';
interface Binding {
    readonly type: string;
}
interface ExecutionState {
    readonly parameter_bindings: {
        readonly [_key: string]: Binding;
    };
    readonly global_override: ExecutionStateGlobalOverride;
    readonly additional_parameters: {
        readonly [_key: string]: Object;
    };
    readonly cancel_after_seconds: number;
    readonly queries: {
        readonly [_key: string]: ExecutionStateGlobalOverride;
    };
}
interface Result {
    readonly name: string;
    readonly id: string;
    readonly type: string;
}
interface ExecutionStateGlobalOverride {
    readonly timerange: TimeRange;
    readonly keep_search_types: string[];
    readonly offset: number;
    readonly query: BackendQuery;
    readonly now: string;
    readonly limit: number;
    readonly search_types: {
        readonly [_key: string]: SearchTypeExecutionState;
    };
    readonly keep_queries: string[];
}
interface TimeRange {
    readonly type: string;
}
interface Type {
    readonly index_names: string[];
    readonly type: string;
    readonly properties: string[];
}
interface UnitId {
    readonly abbrev: string;
    readonly unit_type: string;
}
interface MappedFieldTypeDTO {
    readonly unit: UnitId;
    readonly name: string;
    readonly type: Type;
}
interface SearchError {
    readonly description: string;
}
interface WidgetValueSummary {
    readonly result: Result[];
    readonly field_types: MappedFieldTypeDTO[];
    readonly calculated_at: string;
    readonly errors: SearchError[];
}
interface SearchTypeExecutionState {
    readonly per_page: number;
    readonly offset: number;
    readonly limit: number;
    readonly page: number;
    readonly after: unknown[];
}
interface BackendQuery {
}
/**
 * Get a widget's value
 */
export function value(dashboardId: string, widgetId: string, arg2?: ExecutionState): Promise<WidgetValueSummary> {
    return __request__('POST', `/plugins/org.graylog.plugins.report/widgets/${dashboardId}/${widgetId}/value`, arg2, {}, {
        'Accept': ['application/json']
    });
}
