import type { BOTH_INCLUSION_TYPE, DATA_WAREHOUSE_INCLUSION_TYPE, INDEXER_INCLUSION_TYPE } from 'data-warehouse/Constants';

export enum DATA_WAREHOUSE_CONFIG_ACTION {
  Create = 'create',
  Update = 'update',
  Delete = 'delete'
}

export type RestoreOperation = {
  id: string,
  restore_indices: Array<RestoreIndex>
  timestamp_from: string,
  timestamp_to: string,
  restored_at: string,
  index_failures: number,
  partially_restored: boolean,
  restored_documents: number,
};

export type RestoreIndex = {
  index_set_id: string,
  index_name: string
}

export type DataWarehouse = {
  id: string,
  archive_config_id: string,
  message_count: number,
  archive_name: string,
  timestamp_from: string,
  timestamp_to: string,
  stream_id: string,
  last_known_stream_title: string,
  restore_history: Array<RestoreOperation>,
}

export type DataWarehouseConfig = {
  archive_name: string,
  stream_ids: Array<string>,
  retention_time: string,
  id: string,
  enabled: boolean
}

export enum JobStatus {
  Cancelled = 'cancelled',
  Complete = 'complete',
  Error = 'error',
  Paused = 'paused',
  Runnable = 'runnable',
  Running = 'running',
}

export enum JobType {
  Restore = 'restore',
  Optimize = 'optimize',
  Delete = 'delete',
}

export type DataWarehouseJob = {
  id: string,
  job_type: JobType,
  action: string,
  archive: Array<string>,
  timestamp_from: string,
  timestamp_to: string,
  is_cancelable: boolean,
  is_ackable: boolean,
  target_index_name: string,
  status: JobStatus,
  percent_done: number,
  started_at: string,
  next_time: string,
};

export type DataWarehouseActiveBackend = {
  active_backend: string,
};

export type StreamDataWarehouseConfig = {
  id: string,
  archive_name: string,
  enabled: boolean,
  stream_id: string,
  retention_time: string,
};
export type FieldFilters = {
  field_name: string,
  value: string | Array<string>
};

export type InclusionType = typeof DATA_WAREHOUSE_INCLUSION_TYPE | typeof INDEXER_INCLUSION_TYPE | typeof BOTH_INCLUSION_TYPE;

export type EstimateParams = {
  to: string,
  from: string,
  streamIds: Array<string>,
  inclusionType: InclusionType,
  fieldFilters: Array<FieldFilters>,
}

export type EstimateType = {
  from: string,
  to: string,
  size_estimates: {
    [_key: string]: {
      table_identifier: string,
      archive_name: string,
      row_count: number,
      size_bytes: number,
      files_count: number,
    }
  },
  row_count: number,
  total_size_bytes: number,
  total_files_count: number,
}

export type DataWarehouseActionFormValues = {
  nextTimeRange: {
    type: 'absolute',
    from: string,
    to: string,
  }
  fullDelete?: boolean,
  wipeRestores?: boolean,
  inclusionType?: InclusionType,
  streamIds?: Array<string>,
  fieldFilters?: Array<FieldFilters>,
}
export type DataWarehouseActionValues = {
  from: string,
  to: string,
  full_delete?: boolean,
  wipe_restores?: boolean,
  inclusion_type?: InclusionType,
  stream_ids?: Array<string>,
  field_filters?: Array<FieldFilters>,
}
