import __request__ from 'routing/request';
interface ExecutionState {
    readonly parameter_bindings: {
        readonly [_key: string]: Binding;
    };
    readonly global_override: ExecutionStateGlobalOverride;
    readonly additional_parameters: {
        readonly [_key: string]: Object;
    };
    readonly cancel_after_seconds: number;
    readonly queries: {
        readonly [_key: string]: ExecutionStateGlobalOverride;
    };
}
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
type ReportingWidgetResultArray = ReportingWidgetResult[];
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
interface WidgetConfigDTO {
}
interface ReportingWidgetResult {
    readonly result: WidgetValueSummary;
    readonly widget_id: string;
    readonly dashboard_id: string;
}
interface ReportWidget {
    readonly dashboard_widget_id: string;
    readonly description: string;
    readonly title: string;
    readonly dashboard_id: string;
}
interface Result {
    readonly name: string;
    readonly id: string;
    readonly type: string;
}
interface ReportResponseShort {
    readonly delivery: ReportDelivery;
    readonly timezone: string;
    readonly parameter_values: {
        readonly [_key: string]: Binding;
    };
    readonly description: string;
    readonly positions: ReportWidgetPosition[];
    readonly title: string;
    readonly widgets: ReportWidget[];
    readonly hide_widget_description: boolean;
    readonly layout: ReportLayout;
    readonly hide_widget_query: boolean;
    readonly subtitle: string;
    readonly logo: string;
    readonly id: string;
    readonly parameters: {
        readonly [_key: string]: Parameter;
    };
}
interface PageListResponse_ReportSummaryDTO {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: ReportSummaryDTO[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
interface ReportWidgetReference {
    readonly widget_id: string;
    readonly description: string;
    readonly title: string;
    readonly dashboard_id: string;
}
interface ReportFrequencyConfiguration {
    readonly timerange: TimeRange;
    readonly timezone: string;
    readonly override_widgets_timerange: boolean;
    readonly time: string;
    readonly day_of_month: string;
    readonly day_of_week: string;
}
interface ReportResponse {
    readonly delivery: ReportDelivery;
    readonly timezone: string;
    readonly parameter_values: {
        readonly [_key: string]: Binding;
    };
    readonly description: string;
    readonly positions: ReportWidgetPosition[];
    readonly title: string;
    readonly widgets: ReportDashboardWidget[];
    readonly hide_widget_description: boolean;
    readonly layout: ReportLayout;
    readonly hide_widget_query: boolean;
    readonly subtitle: string;
    readonly id: string;
    readonly parameters: {
        readonly [_key: string]: Parameter;
    };
}
interface UnitId {
    readonly abbrev: string;
    readonly unit_type: string;
}
interface MappedFieldTypeDTO {
    readonly unit: UnitId;
    readonly name: string;
    readonly type: Type;
}
interface ReportWidgetPosition {
    readonly col: number;
    readonly dashboard_widget_id: string;
    readonly row: number;
}
interface ReportLayout {
    readonly orientation: 'portrait' | 'landscape';
    readonly print_footer: boolean;
    readonly footer: string;
    readonly format: 'PDF' | 'CSV' | 'XLSX' | 'YML' | 'JSON' | 'XML';
    readonly header: string;
    readonly print_header: boolean;
    readonly print_page_numbers: boolean;
    readonly print_toc: boolean;
    readonly page_size: 'a4' | 'letter';
}
type stringArray = string[];
interface Binding {
    readonly type: string;
}
interface UsedSearchFilter {
}
interface DeliveryConfiguration {
    readonly frequency_configuration: ReportFrequencyConfiguration;
    readonly frequency: 'HOURLY' | 'DAILY' | 'WEEKLY' | 'MONTHLY';
}
interface EntityAttribute {
    readonly related_property: string;
    readonly filterable: boolean;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'DOUBLE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface ReportRequest {
    readonly layout: ReportLayout;
    readonly hide_widget_query: boolean;
    readonly delivery: ReportDelivery;
    readonly timezone: string;
    readonly parameter_values: {
        readonly [_key: string]: Binding;
    };
    readonly subtitle: string;
    readonly logo: string;
    readonly description: string;
    readonly positions: ReportWidgetPosition[];
    readonly title: string;
    readonly widgets: ReportWidgetReference[];
    readonly hide_widget_description: boolean;
}
interface ReportDeliveryRequest {
    readonly delivery: ReportDelivery;
}
interface ReportPositionsRequest {
    readonly positions: ReportWidgetPosition[];
}
interface ReportDashboardWidget {
    readonly creator_user_id: string;
    readonly cache_time: number;
    readonly dashboard_widget_id: string;
    readonly timerange: TimeRange;
    readonly query: BackendQuery;
    readonly streams: string[];
    readonly description: string;
    readonly filters: UsedSearchFilter[];
    readonly type: string;
    readonly config: WidgetConfigDTO;
    readonly dashboard_id: string;
}
interface ReportDelivery {
    readonly email_body: string;
    readonly scheduling: DeliveryConfiguration[];
    readonly active: boolean;
    readonly user_receivers: string[];
    readonly email_subject: string;
    readonly email_receivers: string[];
}
interface AdditionalReportingParameters {
    readonly time_range: TimeRange;
}
interface ReportSummaryDTO {
    readonly owner: string;
    readonly owner_full_name: string;
    readonly delivery: ReportDelivery;
    readonly widget_count: number;
    readonly timezone: string;
    readonly subtitle: string;
    readonly description: string;
    readonly created_at: string;
    readonly id: string;
    readonly title: string;
    readonly last_generated_at: string;
    readonly status: 'STARTING' | 'RUNNING' | 'RETRYING' | 'GENERATED' | 'FAILURE' | 'DELIVERING' | 'RETRYING_DELIVERY' | 'SUCCESS' | 'DELIVERY_FAILURE';
}
interface ExecutionStateGlobalOverride {
    readonly timerange: TimeRange;
    readonly keep_search_types: string[];
    readonly offset: number;
    readonly query: BackendQuery;
    readonly now: string;
    readonly limit: number;
    readonly search_types: {
        readonly [_key: string]: SearchTypeExecutionState;
    };
    readonly keep_queries: string[];
}
interface AdhocReportRequest {
    readonly execution_state: ExecutionState;
    readonly widgets: ReportWidgetReference[];
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
interface TimeRange {
    readonly type: string;
}
interface Type {
    readonly index_names: string[];
    readonly type: string;
    readonly properties: string[];
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface SearchError {
    readonly description: string;
}
interface WidgetValueSummary {
    readonly result: Result[];
    readonly field_types: MappedFieldTypeDTO[];
    readonly calculated_at: string;
    readonly errors: SearchError[];
}
interface SearchTypeExecutionState {
    readonly per_page: number;
    readonly offset: number;
    readonly limit: number;
    readonly page: number;
    readonly after: unknown[];
}
interface Parameter {
    readonly name: string;
    readonly data_type: string;
    readonly binding: Binding;
    readonly description: string;
    readonly optional: boolean;
    readonly default_value: Object;
    readonly title: string;
    readonly type: string;
}
interface ReportConfiguration {
    readonly delivery: ReportDelivery;
    readonly timezone: string;
    readonly parameter_values: {
        readonly [_key: string]: Binding;
    };
    readonly description: string;
    readonly created_at: string;
    readonly positions: ReportWidgetPosition[];
    readonly title: string;
    readonly widgets: ReportWidget[];
    readonly hide_widget_description: boolean;
    readonly layout: ReportLayout;
    readonly hide_widget_query: boolean;
    readonly subtitle: string;
    readonly logo: string;
    readonly id: string;
}
interface BackendQuery {
}
interface ReportList {
    readonly reports: ReportConfiguration[];
    readonly index: number;
}
/**
 * List all reports.
 */
export function list(): Promise<ReportList> {
    return __request__('GET', '/plugins/org.graylog.plugins.report/reports', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create a report.
 */
export function create(JSONbody: ReportRequest): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.report/reports', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Find reports referencing dashboard with specified id.
 */
export function byDashboardId(dashboardId: string): Promise<stringArray> {
    return __request__('GET', `/plugins/org.graylog.plugins.report/reports/byDashboard/${dashboardId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Find reports referencing widget with specified id.
 */
export function byWidgetId(dashboardId: string, widgetId: string): Promise<stringArray> {
    return __request__('GET', `/plugins/org.graylog.plugins.report/reports/byWidget/${dashboardId}/${widgetId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Find reports referencing any of the widgets with specified ids.
 */
export function byWidgetIds(dashboardId: string, JSONbody: stringArray): Promise<stringArray> {
    return __request__('POST', `/plugins/org.graylog.plugins.report/reports/byWidgets/${dashboardId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get existing reports paged
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function reports(sort: 'id' | 'title' | 'created_at' | 'description' | 'summary' | 'owner' = 'title', page: number = 1, per_page: number = 50, order: 'asc' | 'desc' = 'asc', query?: string, filters?: stringArray): Promise<PageListResponse_ReportSummaryDTO> {
    return __request__('GET', '/plugins/org.graylog.plugins.report/reports/paginated', null, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order, 'query': query, 'filters': filters }, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve values for ad-hoc report submitted.
 */
export function adhocReportValues(JSONbody: AdhocReportRequest): Promise<ReportingWidgetResultArray> {
    return __request__('POST', '/plugins/org.graylog.plugins.report/reports/values', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single report.
 */
export function get(reportId: string): Promise<ReportResponse> {
    return __request__('GET', `/plugins/org.graylog.plugins.report/reports/${reportId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a report.
 */
export function update(reportId: string, JSONbody: ReportRequest): Promise<ReportResponse> {
    return __request__('PUT', `/plugins/org.graylog.plugins.report/reports/${reportId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a report and all its widgets.
 */
export function remove(reportId: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.report/reports/${reportId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update report delivery.
 */
export function updateDelivery(reportId: string, JSONbody: ReportDeliveryRequest): Promise<ReportResponse> {
    return __request__('PUT', `/plugins/org.graylog.plugins.report/reports/${reportId}/delivery`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Download a report instantly.
 */
export function downloadNow(reportId: string, JSONbody?: AdditionalReportingParameters): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.report/reports/${reportId}/downloadNow`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Send report PDF as email
 */
export function sendReport(reportId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.report/reports/${reportId}/email`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Download a report instantly.
 */
export function generateReport(reportId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.report/reports/${reportId}/generate`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a report's logo.
 */
export function getLogo(reportId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.report/reports/${reportId}/logo`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update widget positions of a report.
 */
export function updatePositions(reportId: string, JSONbody: ReportPositionsRequest): Promise<ReportResponse> {
    return __request__('PUT', `/plugins/org.graylog.plugins.report/reports/${reportId}/positions`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single report.
 */
export function getShort(reportId: string): Promise<ReportResponseShort> {
    return __request__('GET', `/plugins/org.graylog.plugins.report/reports/${reportId}/short`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single report as a summary.
 */
export function getSummary(reportId: string): Promise<ReportSummaryDTO> {
    return __request__('GET', `/plugins/org.graylog.plugins.report/reports/${reportId}/summary`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve values for widgets referenced by report.
 */
export function reportValues(reportId: string, arg1?: ExecutionState): Promise<ReportingWidgetResultArray> {
    return __request__('POST', `/plugins/org.graylog.plugins.report/reports/${reportId}/values`, arg1, {}, {
        'Accept': ['application/json']
    });
}
