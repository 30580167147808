import __request__ from 'routing/request';
interface HubLatestVersionResponse {
    readonly required_gl_version: unknown[];
    readonly is_new_version: boolean;
    readonly version: string;
}
interface ListResponse_MitreRow {
    readonly total: number;
    readonly elements: MitreRow[];
}
interface CurrentIlluminateBundleState {
    readonly threat_coverage_sigma_rules_enabled: number;
    readonly threat_coverage_packs_enabled: number;
    readonly version: string;
    readonly enabled: boolean;
    readonly threat_coverage_packs_available: number;
    readonly threat_coverage_sigma_rules_available: number;
}
interface ThreatCoverageSystemState {
    readonly full_coverage: boolean;
    readonly latest_illuminate_version_info: HubLatestVersionResponse;
    readonly current_illuminate_bundle_state: CurrentIlluminateBundleState;
}
interface MitreRow {
    readonly coverage: number;
    readonly children_ids: string[];
    readonly num_children: number;
    readonly name: string;
    readonly id: string;
    readonly num_fully_covered_children: number;
}
/**
 * Gets state of children of the root Mitre node
 */
export function getChildrenStateForRoot(): Promise<ListResponse_MitreRow> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/mitre', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get threat coverage state information
 */
export function getSystemState(): Promise<ThreatCoverageSystemState> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/mitre/threat_coverage_system_state', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Gets state of children of a given Mitre node
 */
export function getChildrenState(node_id?: string): Promise<ListResponse_MitreRow> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/mitre/${node_id}`, null, {}, {
        'Accept': ['application/json']
    });
}
