import __request__ from 'routing/request';
interface FilesConfigResponse {
    readonly max_file_size_bytes: number;
}
interface anyMap {
    readonly [_key: string]: unknown;
}
/**
 * Upload a File
 */
export function upload(): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.files/files', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get configuration metadata
 */
export function config(): Promise<FilesConfigResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.files/files/config', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Download a file
 */
export function download(fileId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.files/files/${fileId}`, null, {}, {
        'Accept': []
    });
}
/**
 * Get file metadata
 */
export function metadata(fileId: string): Promise<anyMap> {
    return __request__('GET', `/plugins/org.graylog.plugins.files/files/${fileId}/metadata`, null, {}, {
        'Accept': ['application/json']
    });
}
