import __request__ from 'routing/request';
interface CheckFieldsResponse {
    readonly ip: string[];
    readonly user: string[];
    readonly hash: string[];
}
interface CheckFieldsRequest {
    readonly ip: {
        readonly [_key: string]: string[];
    };
    readonly user: {
        readonly [_key: string]: string[];
    };
    readonly hash: {
        readonly [_key: string]: string[];
    };
}
interface WatchlistRequest {
    readonly values: string[];
    readonly type: 'ip' | 'user' | 'hash';
}
/**
 * Adds an item to the watchlist
 */
export function add(request: WatchlistRequest): Promise<void> {
    return __request__('POST', '/plugins/org.graylog.plugins.watchlist/watchlist/add', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Query a watchlist for the existence of values
 */
export function checkValues(JSONbody: CheckFieldsRequest): Promise<CheckFieldsResponse> {
    return __request__('POST', '/plugins/org.graylog.plugins.watchlist/watchlist/checkFieldValues', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove an item from the watchlist
 */
export function remove(request: WatchlistRequest): Promise<void> {
    return __request__('POST', '/plugins/org.graylog.plugins.watchlist/watchlist/remove', request, {}, {
        'Accept': ['application/json']
    });
}
