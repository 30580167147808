import __request__ from 'routing/request';
interface PaginatedResponse_RecentActivity {
    readonly [_key: string]: Object;
}
interface PaginatedResponse_LastOpened {
    readonly [_key: string]: Object;
}
/**
 * Get the Last Opened Items for the Start Page for the user
 */
export function getLastOpened(page: number = 1, per_page: number = 5): Promise<PaginatedResponse_LastOpened> {
    return __request__('GET', '/plugins/org.graylog.plugins.views/startpage/lastOpened', null, { 'page': page, 'per_page': per_page }, {
        'Accept': ['application/json']
    });
}
/**
 * Get Recent Activities for the Start Page for the user
 */
export function getRecentActivity(page: number = 1, per_page: number = 5): Promise<PaginatedResponse_RecentActivity> {
    return __request__('GET', '/plugins/org.graylog.plugins.views/startpage/recentActivity', null, { 'page': page, 'per_page': per_page }, {
        'Accept': ['application/json']
    });
}
