import * as React from 'react';
import styled, { css } from 'styled-components';

import type { InstallStatus } from 'illuminate/types';
import { Icon, Spinner, HoverForHelp } from 'components/common';

const StatusTitle = styled.h2`
  margin-bottom: 1rem;
`;

const PackTitles = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepItem = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.3rem 0;

  > :first-child {
    margin-right: 1rem;
  }
`;

const InProgressStep = styled.div`
  -webkit-animation: pulsate 3s ease-out;
  -webkit-animation-iteration-count: infinite; 
  opacity: 0.5;
  display: flex;
  flex-direction: row;
  margin: 0.3rem 0;

  > :first-child {
    margin-right: 1rem;
  }

  @-webkit-keyframes pulsate {
      0% { 
          opacity: 0.5;
      }
      50% { 
          opacity: 1.0;
      }
      100% { 
          opacity: 0.5;
      }
  }
`;

const IconContainer = styled.div`
  width: 16px;
  display: inline-block;
  text-align: left;
`;

const StatusIcon = styled(Icon)<{ $success: boolean; }>(({ theme, $success }) => css`
  color: ${$success ? theme.colors.variant.success : theme.colors.variant.danger};
`);

type Props = {
  installStatusObj: Partial<InstallStatus>,
}

const IlluminateInstallStatus = ({ installStatusObj }: Props) => {
  const renderStatusIcon = (success: boolean) => (
    <IconContainer>
      {success ? (
        <StatusIcon name="check" $success />
      ) : (
        <StatusIcon name="close" />
      )}
    </IconContainer>
  );

  const packTitles = [
    ...installStatusObj.pack_titles,
    ...installStatusObj.spotlight_pack_titles,
  ].sort((a, b) => a.localeCompare(b));

  return (
    <div>
      <StatusTitle>Illuminate v{installStatusObj.illuminate_version} install in progress...</StatusTitle>
      <div>{installStatusObj.disable_packs ? 'Disabling' : 'Enabling'} packs:</div>
      <PackTitles>
        {packTitles.map((packTitle: string) => <strong key={packTitle}>{packTitle}</strong>)}
      </PackTitles>
      {installStatusObj.install_progress.map((installStep) => {
        const { install_in_progress, complete, successful, title, description } = installStep;

        return (
          <div style={{ marginTop: '10px' }} key={`install-step-${title}`}>
            {complete ? (
              <div>
                <StepItem>
                  <div>{title} <HoverForHelp>{description}</HoverForHelp></div>
                  <div>{renderStatusIcon(successful)}</div>
                </StepItem>
              </div>
            ) : (
              <div>
                {install_in_progress ? (
                  <InProgressStep>
                    <div>{title} <HoverForHelp>{description}</HoverForHelp></div>
                    <div><Spinner text="" /></div>
                  </InProgressStep>
                ) : (
                  <StepItem>
                    <div>{title}</div>
                    <div>Not started</div>
                  </StepItem>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default IlluminateInstallStatus;
