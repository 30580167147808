import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import { buildIlluminateQuery } from 'security-app/components/common/Filters/stringifyFilters';
import type { PacksIndexAPIType, Pack, PackFiltersType, PacksSummary, FilterOptions } from 'illuminate/types';
import type { IlluminateHubFilterData } from 'security-app/components/common/Filters/ColumnFilter.types';

export const fetchPacks = async (
  bundleId: string,
  page: number,
  perPage: number,
  query: string = null,
  includeDeps: boolean = false,
): Promise<PacksIndexAPIType> => {
  const params = [
    `page=${page}`,
    `per_page=${perPage}`,
    `include_deps=${includeDeps}`,
  ];
  if (query) params.push(`query=${encodeURI(query)}`);

  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.packs(bundleId).index(params.join('&')).url));
};

export const fetchLatestPacks = async (
  page: number,
  perPage: number,
  query: string = null,
  filters: IlluminateHubFilterData = {},
  includeDeps: boolean = false,
): Promise<PacksIndexAPIType> => {
  const params = [
    `page=${page}`,
    `per_page=${perPage}`,
    `include_deps=${includeDeps}`,
  ];
  if (query) params.push(`query=${encodeURI(query)}`);

  if (filters && Object.keys(filters).length > 0) {
    params.push(`${encodeURI(buildIlluminateQuery(filters))}`);
  }

  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.latest.searchPacksPaginated(params.join('&')).url));
};

export const fetchFilterOptions = async ({ options }: { options: { fields: string[] }}): Promise<FilterOptions> => fetch('PUT', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.filterOptions().url), options);

export const fetchPacksNoPaginated = async (filters: PackFiltersType): Promise<Pack[]> => {
  const params = Object.keys(filters).map((key: string) => `${key}=${Array.isArray(filters[key]) ? filters[key].join(',') : filters[key]}`);

  return fetch('GET', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.latest.searchPacks(params.join('&')).url));
};

export const fetchPacksSummary = async (): Promise<PacksSummary> => (
  fetch('GET', qualifyUrl(EnterpriseApiRoutes.Illuminate.bundles.latest.summary().url))
);

type UpdatePacks = {
  bundleVersion: string
  requestObject: {
    enabled: boolean,
    processingPackIds: string[],
    spotlightPackIds: string[]
  }
}

export type LatestUpdatePacks = {
  requestObject: {
    processing_pack_ids: string[],
    spotlight_pack_ids: string[],
  }
}

export const updatePacks = async ({ bundleVersion, requestObject }: UpdatePacks): Promise<Pack[]> => {
  const { url } = EnterpriseApiRoutes.Illuminate.bundles.packs(bundleVersion).update();

  return fetch('POST', qualifyUrl(url), requestObject);
};

export const latestEnablePacks = async ({ requestObject }: LatestUpdatePacks): Promise<Pack[]> => {
  const { url } = EnterpriseApiRoutes.Illuminate.bundles.latest.enablePacks();

  return fetch('POST', qualifyUrl(url), requestObject);
};

export const latestDisablePacks = async ({ requestObject }: LatestUpdatePacks): Promise<Pack[]> => {
  const { url } = EnterpriseApiRoutes.Illuminate.bundles.latest.disablePacks();

  return fetch('POST', qualifyUrl(url), requestObject);
};
