import * as React from 'react';
import styled, { css } from 'styled-components';

import type { Stream } from 'stores/streams/StreamsStore';
import { Icon, Tooltip } from 'components/common';
import PreviewWarehouseLogsButton from 'data-warehouse/components/PreviewWarehouseLogsButton';

import useStreamDataWarehouseStatus from '../hooks/useStreamDataWarehouseStatus';

const Wrapper = styled.div(({ theme }) => css`
  color: ${theme.colors.variant.default};
  width: fit-content;
`);

type Props = {
  stream: Stream
}

const DataWareHouseCell = ({ stream }: Props) => {
  const { data: config, isFetching } = useStreamDataWarehouseStatus(stream.id);

  if (!stream.is_editable || isFetching) {
    return null;
  }

  const isDataWareHouseEnabled = config?.enabled || false;

  if (isDataWareHouseEnabled) {
    return (
      <PreviewWarehouseLogsButton streamId={stream.id} />
    );
  }

  return (
    <Tooltip withArrow position="right" label="Data Warehouse is disabled">
      <Wrapper>
        <Icon name="cancel" />
      </Wrapper>
    </Tooltip>
  );
};

export default DataWareHouseCell;
