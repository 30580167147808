import React from 'react';
import styled, { css } from 'styled-components';

import { ControlledTableList, Icon } from 'components/common';
import { Button } from 'components/bootstrap';
import type { Pack } from 'illuminate/types';
import { GLCheckbox } from 'common/components';
import {
  useSelectedRows,
  useSelectedRowsDispatch,
} from 'common/contexts';

const FlexWrap = styled.div(({ theme }) => css`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  @media (min-width: ${theme.breakpoints.min.md}) {
    flex-wrap: nowrap;
  }
`);

const H4 = styled.h4`
  display: flex;
  align-items: center;
`;

const EnabledMark = styled.span(({ theme }) => css`
  color: ${theme.colors.variant.success};
  font-weight: bold;
  font-size: ${theme.fonts.size.tiny};
  margin-left: ${theme.spacings.sm};
`);

const PackItemRow = styled.span`
  display: flex;
  align-items: center;
`;

const StyledGLCheckbox = styled(GLCheckbox)(({ theme }) => css`
  margin-right: ${theme.spacings.sm};
`);

const StyledTableListItem = styled(ControlledTableList.Item)<{$enabled?: boolean}>`
  padding: 10px;
`;

const MoreButton = styled(Button)`
  outline: 0;
  border: none;
  margin-left: 10px;

  &:focus, &:focus-visible, &:active {
    outline: none;
    border: none;
  }
`;

const Title = styled.span<{ $compatible: boolean }>(({ theme, $compatible }) => css`
  color: ${$compatible ? theme.colors.global.textDefault : theme.colors.input.colorDisabled};
  margin-left: ${theme.spacings.sm};
`);

type Props = {
  packItem: Pack,
  setCurrentDescription: (id: string) => void,
  focusedId?: string,
}

const IlluminatePackItem = ({ packItem, setCurrentDescription, focusedId = null }: Props) => {
  const { pack_id: packId, title: packTitle, enabled: packEnabled } = packItem;

  const selectedPacks = useSelectedRows();
  const selectedPacksDispatch = useSelectedRowsDispatch();

  const onSelect = (pack: Pack) => (e: React.BaseSyntheticEvent) => {
    if (e.target.checked) {
      selectedPacksDispatch({ type: 'add', payload: [pack], key: 'pack_id' });
    } else {
      selectedPacksDispatch({ type: 'remove', payload: [pack], key: 'pack_id' });
    }
  };

  const isSelected = (pack: Pack) => (
    !!selectedPacks.find((sPack: Pack) => pack.pack_id === sPack.pack_id)
  );

  return (
    <StyledTableListItem>
      <FlexWrap>
        <H4>
          <PackItemRow>
            <StyledGLCheckbox data-testid={`select-${packId}`}
                              checked={isSelected(packItem)}
                              onChange={onSelect(packItem)}
                              disabled={!packItem.is_compatible} />
            <Title $compatible={packItem.is_compatible}>{packTitle}</Title>
          </PackItemRow>

          {packEnabled && (<EnabledMark><Icon name="check" /> Enabled</EnabledMark>)}
        </H4>
        <MoreButton id={`${packId}-details`}
                    data-testid={`${packId}-details`}
                    type="button"
                    bsStyle="link"
                    onClick={() => setCurrentDescription(packId)}>
          {focusedId && focusedId === packId ? 'Less' : 'More'}
        </MoreButton>
      </FlexWrap>
    </StyledTableListItem>
  );
};

export default IlluminatePackItem;
