import __request__ from 'routing/request';
interface PrioritizeRequest {
    readonly priorities: Priority[];
}
interface Priority {
    readonly id: string;
    readonly text: string;
    readonly priority: number;
}
type PriorityArray = Priority[];
/**
 * Get a list of asset priorities
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function listPriorities(sort: 'text' | 'priority' = 'priority', page: number = 1, per_page: number = 15, query?: string, direction: 'asc' | 'desc' = 'asc'): Promise<unknown[]> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/assets/priorities', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new asset priority
 */
export function create(priority: string): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/assets/priorities', priority, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all possible asset priority values
 */
export function getAll(): Promise<PriorityArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/assets/priorities/all', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Reprioritize the weight of existing asset priorities
 */
export function updatePriorities(priorities: PrioritizeRequest): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/assets/priorities/reprioritize', priorities, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an asset priority
 */
export function update(id: string, priority: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/assets/priorities/${id}`, priority, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an asset priority
 */
export function remove(id: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/assets/priorities/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
