import useActionCondition from 'security-app/components/Investigations/plugin/useActionCondition';
import type { Event } from 'components/events/events/types';

export const isNotSystemEvent = (event: Event) => !event.event_definition_type.startsWith('system-notifications');

const useEventActionCondition = (events: Array<Event>) => {
  const hasNonSystemEvents = !!events.filter(isNotSystemEvent).length;

  return useActionCondition() && hasNonSystemEvents;
};

export default useEventActionCondition;
