import type { Attribute } from 'stores/PaginationTypes';
import AppConfig from 'util/AppConfig';

export const DATA_WAREHOUSE_API_ROUTES = {
  DataWarehouseAPIController: {
    paginated: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse' }),
    get: (id: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/archives/${id}` }),
    get_stream_datawarehouse: (streamId: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/${streamId}/archive` }),
    delete: (streamId: string, retrievalOperationId: string) => ({
      url: `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/archive/${streamId}/retrieved/${retrievalOperationId}`,
    }),
    bulk_delete: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/delete' }),
    bulk_retrieval: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/retrieve' }),
    config: {
      base: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/config' }),
    },
    backend: {
      base: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends' }),
      get: (id: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends/${id}` }),
      delete: (id: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends/${id}` }),
      update: (id: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/backends/${id}` }),
    },
    backendConfig: {
      base: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/config' }),
    },
    estimate: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/size_estimate' }),
    job: {
      progress: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/progress' }),
      stream_jobs: (streamId: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/progress/${streamId}` }),
      cancel: (id: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/${id}` }),
    },
    stream_data_warehouse_config: (streamId: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/${streamId}/config` }),
    stream_data_warehouse_set_config_status: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/config/enable' }),
    filterableFields: () => ({ url: 'plugins/org.graylog.plugins.datawarehouse/data_warehouse/filterable_fields' }),
    stream_retention: (streamId: string) => ({ url: `plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/${streamId}/retention_time` }),
  },
};
export const DATA_WAREHOUSE_CONFIG_QUERY_KEYS = ['data-warehouse', 'config'];
export const DATA_WAREHOUSE_BACKEND_QUERY_KEYS = ['data-warehouse', 'backend'];
export const DATA_WAREHOUSE_BACKEND_CONFIG_QUERY_KEYS = ['data-warehouse', 'backend', 'config'];

type DataWareHouseSettings = {
  data_warehouse_enabled: boolean
}
const settings: DataWareHouseSettings = AppConfig.pluginUISettings('org.graylog.plugins.storage.data_warehouse');

export const DATAWAREHOUSE_ATTRIBUTE_NAME = 'datawarehouse';
export const DATAWAREHOUSE_ATTRIBUTES: Array<Attribute> = [
  { id: DATAWAREHOUSE_ATTRIBUTE_NAME, title: 'Data Warehouse' },
];

export const DATA_WAREHOUSE_ENABLED = settings?.data_warehouse_enabled === true;

export const DATA_WAREHOUSE_LICENSE_TITLE = 'Data Warehouse is disabled';
export const DATA_WAREHOUSE_LICENSE_TEXT = 'Data Warehouse is disabled because there is no valid license. You can still restore existing archives, but Graylog will not archive new data.';
export const DATA_WAREHOUSE_INCLUSION_TYPE = 'DATA_WAREHOUSE_ONLY';
export const INDEXER_INCLUSION_TYPE = 'INDEXER_ONLY';
export const BOTH_INCLUSION_TYPE = 'BOTH';
export const HIDE_DATAWAREHOUSE_RETRIEVAL_HELP_TEXT_KEY = 'datawarehouse-retrieval-hide-help-text';
export const DATAWAREHOUSE_SECTION_OPENED_KEY = 'datawarehouse-section-opened';
