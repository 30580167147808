import React, { useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import Routes from 'routing/Routes';
import { Select, Spinner } from 'components/common';
import { ControlLabel, SplitButton, Menu, DeleteMenuItem } from 'components/bootstrap';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { Link } from 'components/common/router';
import { BundleContext } from 'illuminate/context/BundleContext';
import { useUpdateBundle, useDeleteBundle } from 'illuminate/hooks/useBundlesAPI';

import NewBundleAlert from './IlluminateNewBundleAlert';

const StyledNewBundleAlert = styled(NewBundleAlert)`
  max-width: 450px;
`;

const BundleVersion = styled.div`
  display: flex;
`;

const StyledSelect = styled(Select)(({ theme }) => css`
  flex: 1;
  margin-right: ${theme.spacings.xs};
  width: 160px;
`);

const InstallCTA = styled.p(({ theme }) => css`
  && {
    text-align: right;
    margin: 0;
    font-size: ${theme.fonts.size.small};
    padding-top: ${theme.spacings.xs};
  }
`);

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px
`;

const IlluminateBundleVersion = () => {
  const { bundles, bundleVersion } = useContext(BundleContext);

  const [disabledBundleConfirm, setDisabledBundleConfirm] = useState<boolean>(true);
  const [nextVersion, setNextVersion] = useState<string>(bundleVersion);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState<boolean>(false);

  const { updateBundle, updatingBundle } = useUpdateBundle();
  const { deleteBundle, deletingBundle } = useDeleteBundle();

  const bundleOptions = useMemo(() => bundles.map((bundle) => ({
    label: bundle.title,
    value: bundle.version,
  })), [bundles]);

  const toggleShowConfirmDialog = () => {
    setShowConfirmDialog(!showConfirmDialog);
  };

  const toggleShowDeleteConfirmDialog = () => {
    setShowDeleteConfirmDialog(!showDeleteConfirmDialog);
  };

  const confirmBundleVersion = async () => {
    await updateBundle({ bundleId: nextVersion, bundleData: { enabled: true } });

    setDisabledBundleConfirm(true);
    toggleShowConfirmDialog();
  };

  const confirmDeleteBundleVersion = async () => {
    setNextVersion(bundleVersion);
    setDisabledBundleConfirm(true);
    await deleteBundle({ bundleId: nextVersion });
    toggleShowDeleteConfirmDialog();
  };

  return (
    <Row>
      <StyledNewBundleAlert />
      <div>
        <ControlLabel htmlFor="illuminate-bundles">Illuminate Bundle Version</ControlLabel>
        <BundleVersion>
          <StyledSelect options={bundleOptions}
                        value={nextVersion}
                        name="illuminate-bundles"
                        inputId="illuminate-bundles"
                        size="small"
                        clearable={false}
                        onChange={(newVersion: string) => {
                          setNextVersion(newVersion);
                          setDisabledBundleConfirm(newVersion === bundleVersion);
                        }} />
          <SplitButton id="bundle-dropdown"
                       onClick={toggleShowConfirmDialog}
                       bsStyle="success"
                       bsSize="small"
                       title="Activate"
                       disabled={disabledBundleConfirm}>
            <Menu>
              <DeleteMenuItem eventKey="1" onClick={toggleShowDeleteConfirmDialog} />
            </Menu>
          </SplitButton>
        </BundleVersion>

        <InstallCTA>
          <Link to={Routes.pluginRoute('ILLUMINATE_INSTALL')}>Install Another Bundle</Link>
        </InstallCTA>

      </div>
      <ConfirmDialog show={showConfirmDialog}
                     btnConfirmText={updatingBundle ? <Spinner text="Activating..." delay={0} /> : 'Confirm'}
                     btnConfirmDisabled={updatingBundle}
                     onCancel={toggleShowConfirmDialog}
                     onConfirm={confirmBundleVersion}
                     title="Are you sure?">
        <>
          <p>You are about to change Illuminate to version <strong>{nextVersion}</strong>.</p>
          <p>This will pause message processing on all Graylog nodes while the change is applied.</p>
          <p>All previously enabled packs will remain active in the new version.</p>
          <p>Do you wish to continue?</p>
        </>

      </ConfirmDialog>

      <ConfirmDialog show={showDeleteConfirmDialog}
                     btnConfirmText={deletingBundle ? <Spinner text="Deleting..." delay={0} /> : 'Confirm'}
                     btnConfirmDisabled={deletingBundle}
                     onConfirm={confirmDeleteBundleVersion}
                     onCancel={toggleShowDeleteConfirmDialog}
                     title="Are you sure?">
        <>
          <p>You are about to delete Illuminate version <strong>{nextVersion}</strong>.</p>
          <p>This action cannot be undone.</p>
          <p>Do you wish to continue?</p>
        </>
      </ConfirmDialog>
    </Row>
  );
};

export default IlluminateBundleVersion;
