import __request__ from 'routing/request';
interface TermsVersion {
    readonly date: string;
    readonly text: string;
    readonly version: string;
}
interface Consent {
    readonly cluster_id: string;
    readonly registered_at: string;
    readonly registered_with_server_version: string;
    readonly user_name: string;
    readonly terms_and_conditions_version: string;
}
interface TermsResponse {
    readonly terms: TermsVersion[];
}
interface InvestigationSummary {
    readonly summary: string;
    readonly template: string;
    readonly model: string;
}
interface AIParameters {
    readonly top_p: number;
    readonly max_gen_length: number;
    readonly temperature: number;
}
interface InvestigationReportResponse {
    readonly text: string;
}
interface SummaryRequestParameters {
    readonly template: string;
    readonly ai_parameters: AIParameters;
    readonly model: string;
    readonly prompt: string;
    readonly logs: string[];
    readonly events: string[];
    readonly invalidate_cached_summary: boolean;
}
interface QuotaStatusResponse {
    readonly limit: number;
    readonly used: number;
    readonly reset_at: string;
    readonly remaining: number;
}
interface ConsentsListResponse {
    readonly consents: Consent[];
}
interface TermsAcceptanceResponse {
    readonly terms: TermsVersion;
    readonly acceptance_details: string;
    readonly accepted: boolean;
}
/**
 * Provides details about acceptance status of latest terms version
 */
export function acceptance(): Promise<TermsAcceptanceResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations/summary/acceptance', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieves all consents of a user invoking this call
 */
export function consents(): Promise<ConsentsListResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations/summary/consents', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Accepts terms and condition with certain version for certain user
 */
export function addConsent(terms_and_conditions_version: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/investigations/summary/consents/${terms_and_conditions_version}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieves default prompt template used for AI investigation summary generation
 */
export function defaultPrompt(): Promise<string> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations/summary/prompt', null, {}, {
        'Accept': ['text/plain']
    });
}
/**
 * Retrieves prompt template used for AI investigation summary generation
 */
export function prompt(templateName: string): Promise<string> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/investigations/summary/prompt/${templateName}`, null, {}, {
        'Accept': ['text/plain']
    });
}
/**
 * Provides details about quota status related to AI-related calls
 */
export function quotaStatus(): Promise<QuotaStatusResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations/summary/quota', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieves all terms and condition of AI investigation summary generation
 */
export function allTerms(): Promise<TermsResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations/summary/terms/all', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieves latest terms and condition of AI investigation summary generation
 */
export function latestTerms(): Promise<TermsVersion> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations/summary/terms/latest', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * creates a summary for the given investigation
 */
export function investigationReport(investigationId: string): Promise<InvestigationReportResponse> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/investigations/summary/${investigationId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * creates a summary for the given investigation
 */
export function summary(investigationId: string, JSONBody?: SummaryRequestParameters): Promise<InvestigationSummary> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/investigations/summary/${investigationId}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
