import __request__ from 'routing/request';
interface AuditLogStatusSummaryMap {
    readonly [_key: string]: AuditLogStatusSummary;
}
interface AuditLogStatusSummary {
    readonly message_signing_enabled: boolean;
    readonly mongodb_collection_name: string;
    readonly log4j_marker_name: string;
    readonly log4j_enabled: boolean;
    readonly log4j_appender_config: {
        readonly [_key: string]: {
            readonly [_key: string]: Object;
        };
    };
    readonly log4j_logger_name: string;
}
/**
 * Get audit log status
 */
export function status(): Promise<AuditLogStatusSummary> {
    return __request__('GET', '/plugins/org.graylog.plugins.auditlog/status', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get audit log status of all nodes
 */
export function statusAll(): Promise<AuditLogStatusSummaryMap> {
    return __request__('GET', '/plugins/org.graylog.plugins.auditlog/status/all', null, {}, {
        'Accept': ['application/json']
    });
}
