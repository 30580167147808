import React from 'react';

import { Button, ButtonToolbar, Table } from 'components/bootstrap';

import type { Input, ForwarderInputTypes } from '../Types';

type Props = {
  inputs: Array<Input>,
  inputTypes: ForwarderInputTypes,
  onInputDelete?: (input: Input) => void
  onInputEdit?: (input: Input) => void
  withActions?: boolean,
};

const InputsTable = ({ inputs, inputTypes, onInputDelete = () => {}, onInputEdit = () => {}, withActions = false }: Props) => (
  <Table striped>
    <thead>
      <tr>
        <th>Title</th>
        <th>Type</th>
        {withActions && <th>&nbsp;</th>}
      </tr>
    </thead>
    <tbody>
      {inputs.map((input) => {
        const { title, type } = input;

        return (
          <tr key={title}>
            <td>{title}</td>
            <td>{inputTypes[type] ? inputTypes[type] : type}</td>
            {withActions && (
            <td>
              <ButtonToolbar>
                <Button bsStyle="danger" bsSize="xs" onClick={() => onInputDelete(input)}>Delete</Button>
                <Button bsStyle="success" bsSize="xs" onClick={() => onInputEdit(input)}>Edit</Button>
              </ButtonToolbar>
            </td>
            )}
          </tr>
        );
      })}
    </tbody>
  </Table>
);

export default InputsTable;
