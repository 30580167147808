import __request__ from 'routing/request';
interface TeamSyncBackendDTO {
    readonly selection_type: 'all' | 'include' | 'exclude';
    readonly selection: string[];
    readonly auth_service_backend_id: string;
    readonly default_roles: string[];
    readonly id: string;
    readonly config: TeamSyncBackendConfig;
}
interface AuthServiceBackendConfig {
    readonly type: string;
}
interface TeamSyncBackendConfig {
    readonly type: string;
}
interface AuthServiceBackendDTO {
    readonly default_roles: string[];
    readonly description: string;
    readonly id: string;
    readonly title: string;
    readonly config: AuthServiceBackendConfig;
}
interface TeamSyncTestBackendRequest {
    readonly team_sync_backend_configuration: TeamSyncBackendDTO;
    readonly auth_service_backend_id: string;
    readonly auth_service_backend_configuration: AuthServiceBackendDTO;
}
/**
 * Test team-sync backend configuration
 */
export function test(JSONbody: TeamSyncTestBackendRequest): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.security/team-sync/test/backend', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
