import * as React from 'react';
import { useCallback } from 'react';

import Popover from 'components/common/Popover';
import type { SelectedSearchFilter } from 'search-filter/types';
import type { SearchFilterEdit } from 'search-filter/components/search-filter-bar/SearchFilterForm';
import SearchFilterForm from 'search-filter/components/search-filter-bar/SearchFilterForm';
import useUserSearchFilterMutation from 'search-filter/hooks/useUserSearchFilterMutation';
import generateId from 'logic/generateId';
import { REFERENCED_USER_SEARCH_FILTER_TYPE, INLINE_SEARCH_FILTER_TYPE } from 'search-filter/constants';
import { CurrentUserStore } from 'stores/users/CurrentUserStore';

type Props = React.PropsWithChildren<{
  onSubmit: (searchFilter: SelectedSearchFilter) => void,
  onCancel: () => void,
  show: boolean,
}>;

const SearchFilterCreatePopover = ({ children, onSubmit, onCancel, show }: Props) => {
  const { post } = useUserSearchFilterMutation();

  const handleFormSubmit = useCallback((filterData: SearchFilterEdit, checked: boolean) => {
    if (checked) {
      return post({ data: filterData }).then((data: SelectedSearchFilter) => CurrentUserStore.reload()
        .then(() => onSubmit({ ...data, frontendId: data.id, type: REFERENCED_USER_SEARCH_FILTER_TYPE })));
    }

    const newId = generateId();

    return onSubmit({ ...filterData, id: newId, frontendId: newId, type: INLINE_SEARCH_FILTER_TYPE });
  }, [onSubmit, post]);

  const onClose = useCallback(() => onCancel(), [onCancel]);

  return (
    <Popover position="bottom-start" width={450} opened={show} withArrow onClose={onClose} closeOnClickOutside={false} withinPortal>
      <Popover.Target>
        {children}
      </Popover.Target>
      <Popover.Dropdown title="Create Filter" id="create-search-filter-dropdown">
        <SearchFilterForm onCancel={onCancel}
                          onSubmit={handleFormSubmit}
                          checkboxLabel='Save to "My Filters"'
                          helpText='This action will save this filter in your "My Filters" collection. These filters can be reused in other searches. Changes to this filter will affect all related searches.'
                          helpTitle='Save to "My Filters"'
                          submitButtonText="Create filter"
                          submitLoadingText="Creating filter..." />
      </Popover.Dropdown>
    </Popover>
  );
};

export default SearchFilterCreatePopover;
