import __request__ from 'routing/request';
interface TimeRange {
    readonly type: string;
}
interface DataWarehouseQueryResult {
    readonly stats: ExecutionStats;
    readonly messages: DataWarehouseRow[];
    readonly status: 'OK' | 'ERROR' | 'TIMEOUT';
}
interface RetrievalFilter {
    readonly value: Object;
    readonly field_name: string;
}
interface DataWarehouseRow {
    readonly message: {
        readonly [_key: string]: Object;
    };
}
interface DataWarehouseQueryRequest {
    readonly search_after: SearchAfter;
    readonly timerange: TimeRange;
    readonly field_filters: RetrievalFilter[];
    readonly inclusion_type: 'DATA_WAREHOUSE_ONLY' | 'INDEXER_ONLY' | 'BOTH';
    readonly fields: string[];
}
interface SearchAfter {
    readonly id: string;
    readonly timestamp: string;
}
interface ExecutionStats {
    readonly duration: string;
    readonly size_of_opened_files: number;
    readonly opened_files: number;
    readonly records_filtered_in_memory: number;
    readonly files_matching_filter_expression: number;
}
/**
 * Query data warehouse stream
 */
export function query(streamId: string = '000000000000000000000001', limit: number = 10, timeout?: number, request?: DataWarehouseQueryRequest): Promise<DataWarehouseQueryResult> {
    return __request__('POST', `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/query/${streamId}`, request, { 'limit': limit, 'timeout': timeout }, {
        'Accept': ['application/json']
    });
}
