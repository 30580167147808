import * as React from 'react';

import useIsInvestigationDetailsOnSecurityUI from 'security-app/components/Investigations/plugin/shared/useIsInvestigationDetailsOnSecurityUI';
import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';
import { useAddEvidence, useGetInvestigationDetails } from 'security-app/hooks/useInvestigationsAPI';
import type { ModalHandler } from 'security-app/components/Investigations/ModalHandler.type';
import { MenuItem } from 'components/bootstrap';
import type { Event } from 'components/events/events/types';
import useEventActionCondition, { isNotSystemEvent } from 'security-app/components/Investigations/plugin/useEventActionCondition';

import AddEvidenceModal from './shared/AddEvidenceModal';

export const EventInvestigationsModal = React.forwardRef(({ events }: { events: Array<Event> }, ref: (r: ModalHandler) => void) => {
  const isAvailable = useEventActionCondition(events);

  if (!isAvailable) {
    return null;
  }

  const eventIds = events.filter(isNotSystemEvent).map(({ id }) => id);

  return (
    <AddEvidenceModal ids={eventIds} type="events" ref={ref} />
  );
});

const InvestigationsEventAction = ({ events, modalRef }: { events: Array<Event>, modalRef: () => ModalHandler }) => {
  const eventIds = events.filter(isNotSystemEvent).map(({ id }) => id);
  const { selectedInvestigationId, setCollapsed } = useInvestigationDrawer();
  const { currentInvestigation } = useGetInvestigationDetails(selectedInvestigationId);

  const hideSelectAnInvestigation = useIsInvestigationDetailsOnSecurityUI();

  const { addEvidence } = useAddEvidence();

  const disabledAdd = !currentInvestigation || currentInvestigation?.archived;

  const onAddEvent = async () => {
    if (disabledAdd) return;

    await addEvidence({
      invId: selectedInvestigationId,
      payload: { events: eventIds },
    });

    setCollapsed(false);
  };

  return (
    <>
      <MenuItem onClick={onAddEvent} disabled={disabledAdd} icon="extension">
        Add to investigation
      </MenuItem>
      {hideSelectAnInvestigation || (
      <MenuItem onClick={() => modalRef()?.toggle()} icon="extension">
        Select an investigation
      </MenuItem>
      )}
    </>
  );
};

export default InvestigationsEventAction;
