import __request__ from 'routing/request';
interface PaginatedResponse_ForwarderInputProfileDto {
    readonly [_key: string]: Object;
}
interface UpdateForwarderInputProfileRequest {
    readonly description: string;
    readonly title: string;
}
interface ForwarderInputDto {
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly created_at: string;
    readonly id: string;
    readonly title: string;
    readonly input_profile_id: string;
    readonly type: string;
    readonly version: number;
}
interface ForwarderInputProfileDto {
    readonly created_at: string;
    readonly description: string;
    readonly id: string;
    readonly title: string;
}
interface PaginatedResponse_ForwarderInputDto {
    readonly [_key: string]: Object;
}
interface CreateForwarderInputProfileRequest {
    readonly description: string;
    readonly title: string;
}
interface CreateForwarderInputRequest {
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly title: string;
    readonly type: string;
}
/**
 * Get all Input Profiles
 */
export function list(page: number = 1, per_page: number = 15, query?: string): Promise<PaginatedResponse_ForwarderInputProfileDto> {
    return __request__('GET', '/plugins/org.graylog.plugins.forwarder/forwarder/profiles', null, { 'page': page, 'per_page': per_page, 'query': query }, {
        'Accept': ['application/json']
    });
}
/**
 * Create an Input Profile
 */
export function create(JSONBody?: CreateForwarderInputProfileRequest): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.forwarder/forwarder/profiles', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a specific Input Profile
 */
export function get(inputProfileId: string): Promise<ForwarderInputProfileDto> {
    return __request__('GET', `/plugins/org.graylog.plugins.forwarder/forwarder/profiles/${inputProfileId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an Input Profile
 */
export function update(inputProfileId: string, JSONBody?: UpdateForwarderInputProfileRequest): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.forwarder/forwarder/profiles/${inputProfileId}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an Input Profile
 */
export function remove(inputProfileId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.forwarder/forwarder/profiles/${inputProfileId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all Forwarder Inputs
 */
export function listByinputProfileIdAndpageAndper_pageAndquery(inputProfileId: string, page: number = 1, per_page: number = 15, query?: string): Promise<PaginatedResponse_ForwarderInputDto> {
    return __request__('GET', `/plugins/org.graylog.plugins.forwarder/forwarder/profiles/${inputProfileId}/inputs`, null, { 'page': page, 'per_page': per_page, 'query': query }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a Forwarder Input
 */
export function createByinputProfileIdAndJSONBody(inputProfileId: string, JSONBody?: CreateForwarderInputRequest): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.forwarder/forwarder/profiles/${inputProfileId}/inputs`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create a sample Random HTTP input.
 */
export function createSampleInput(inputProfileId: string): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.forwarder/forwarder/profiles/${inputProfileId}/inputs/createSampleInput`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a Forwarder Input
 */
export function deleteInput(inputProfileId: string, forwarderInputId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.forwarder/forwarder/profiles/${inputProfileId}/inputs/${forwarderInputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a Forwarder Input
 */
export function updateByinputProfileIdAndforwarderInputIdAndJSONBody(inputProfileId: string, forwarderInputId: string, JSONBody?: CreateForwarderInputRequest): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.forwarder/forwarder/profiles/${inputProfileId}/inputs/${forwarderInputId}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a specific Forwarder Input
 */
export function getInput(inputProfileId: string, forwarderInputId: string): Promise<ForwarderInputDto> {
    return __request__('GET', `/plugins/org.graylog.plugins.forwarder/forwarder/profiles/${inputProfileId}/inputs/${forwarderInputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
