import __request__ from 'routing/request';
interface EnterpriseFailureHandlerConfig {
    readonly persistFailedMessage: boolean;
    readonly submitProcessingFailure: boolean;
    readonly keepFailedMessageDuplicate: boolean;
    readonly persistIndexingFailure: boolean;
}
/**
 * Get Enterprise Failure Handler configuration
 */
export function read(): Promise<EnterpriseFailureHandlerConfig> {
    return __request__('GET', '/plugins/org.graylog.plugins.failure/config', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update Enterprise Failure Handler configuration
 */
export function edit(body: EnterpriseFailureHandlerConfig): Promise<EnterpriseFailureHandlerConfig> {
    return __request__('PUT', '/plugins/org.graylog.plugins.failure/config', body, {}, {
        'Accept': ['application/json']
    });
}
