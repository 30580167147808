import __request__ from 'routing/request';
interface MetricDto {
    readonly name: string;
    readonly type: 'METER';
}
interface CreateForwarderRequest {
    readonly hostname: string;
    readonly description: string;
    readonly input_profile_id: string;
    readonly title: string;
    readonly node_id: string;
}
interface ForwarderResponse {
    readonly forwarder: ForwarderDto;
    readonly context: {
        readonly [_key: string]: Object;
    };
}
interface UpdateForwarderRequest {
    readonly hostname: string;
    readonly description: string;
    readonly input_profile_id: string;
    readonly title: string;
}
interface ForwarderDto {
    readonly hostname: string;
    readonly heartbeat_interval_millis: number;
    readonly created_at: string;
    readonly description: string;
    readonly state: 'CONNECTED' | 'DISCONNECTED';
    readonly metrics: MetricDto[];
    readonly id: string;
    readonly input_profile_id: string;
    readonly last_seen_at: string;
    readonly title: string;
    readonly version: string;
    readonly node_id: string;
}
interface PaginatedResponse_ForwarderDto {
    readonly [_key: string]: Object;
}
/**
 * Get all Forwarders
 * @param sort The field to sort the result on
 * @param order The sort direction
 * @param state Filter by Forwarder state
 */
export function list(sort: 'title' | 'description' | 'hostname' | 'created_at' | 'last_seen_at' = 'title', page: number = 1, per_page: number = 15, query?: string, order: 'asc' | 'desc' = 'asc', state: 'connected' | ' disconnected' | ' any' | 'any' = 'any'): Promise<PaginatedResponse_ForwarderDto> {
    return __request__('GET', '/plugins/org.graylog.plugins.forwarder/forwarder/forwarders', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order, 'state': state }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a Forwarder
 */
export function create(JSONBody?: CreateForwarderRequest): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.forwarder/forwarder/forwarders', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a specific Forwarder
 */
export function getByNodeId(forwarderId: string): Promise<ForwarderResponse> {
    return __request__('GET', `/plugins/org.graylog.plugins.forwarder/forwarder/forwarders/byNodeId/${forwarderId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get non-configured Forwarders
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function pendingConfiguration(sort: 'title' | 'description' | 'hostname' | 'created_at' | 'last_seen_at' = 'created_at', page: number = 1, per_page: number = 0, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_ForwarderDto> {
    return __request__('GET', '/plugins/org.graylog.plugins.forwarder/forwarder/forwarders/pending_configuration', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Get a specific Forwarder
 */
export function get(forwarderId: string): Promise<ForwarderResponse> {
    return __request__('GET', `/plugins/org.graylog.plugins.forwarder/forwarder/forwarders/${forwarderId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a Forwarder
 */
export function update(forwarderId: string, JSONBody?: UpdateForwarderRequest): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.forwarder/forwarder/forwarders/${forwarderId}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a Forwarder
 */
export function remove(forwarderId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.forwarder/forwarder/forwarders/${forwarderId}`, null, {}, {
        'Accept': ['application/json']
    });
}
