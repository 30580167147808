import __request__ from 'routing/request';
interface CustomNotificationDetails {
    readonly atLogin: boolean;
    readonly isGlobal: boolean;
    readonly variant: string;
    readonly shortMessage: string;
    readonly hiddenTitle: boolean;
    readonly isDismissible: boolean;
    readonly isActive: boolean;
    readonly longMessage: string;
    readonly title: string;
}
interface CustomNotificationDetailsMap {
    readonly [_key: string]: CustomNotificationDetails;
}
/**
 * Get all existing notifications
 */
export function getAllNotifications(): Promise<CustomNotificationDetailsMap> {
    return __request__('GET', '/plugins/org.graylog.plugins.customization/notifications', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new notification
 */
export function createNotification(details?: CustomNotificationDetails): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.customization/notifications', details, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single notification
 */
export function getSingleNotification(id?: string): Promise<CustomNotificationDetails> {
    return __request__('GET', `/plugins/org.graylog.plugins.customization/notifications/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an existing notification
 */
export function updateNotification(id?: string, details?: CustomNotificationDetails): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.customization/notifications/${id}`, details, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an existing notifications
 */
export function deleteNotification(notificationId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.customization/notifications/${notificationId}`, null, {}, {
        'Accept': ['application/json']
    });
}
