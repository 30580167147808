import __request__ from 'routing/request';
/**
 * Returns all existing archives
 */
export function all(): Promise<unknown> {
    return __request__('GET', '/plugins/org.graylog.plugins.archive/cluster/archives', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Returns all existing archives for the given backend
 */
export function allForBackend(backendId?: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.archive/cluster/archives/backend/${backendId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete the archive for the given backend and archive id
 */
export function deleteByBackend(backendId: string, archiveId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.archive/cluster/archives/backend/${backendId}/${archiveId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Restore the given archive
 */
export function restoreFromBackend(backendId: string, archiveId: string): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.archive/cluster/archives/backend/${backendId}/${archiveId}/restore`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Returns the matching archives from the catalog
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function catalog(sort: 'created_at' | ' index_name' | ' document_count' = 'created_at', page: number = 1, per_page: number = 50, order: 'asc' | ' desc' | 'desc' = 'desc', query?: string): Promise<unknown> {
    return __request__('GET', '/plugins/org.graylog.plugins.archive/cluster/archives/catalog', null, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order, 'query': query }, {
        'Accept': ['application/json']
    });
}
/**
 * Exports a list of certain archive attributes
 * @param sort The field to sort the result on
 * @param exportType The attribute to be exported
 */
export function exportFilenames(sort: 'created_at' | ' index_name' | ' document_count' = 'created_at', exportType: 'filename' = 'filename', query?: string, limit: number = 10000): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.archive/cluster/archives/catalog/export/${exportType}`, null, { 'query': query, 'limit': limit, 'sort': sort }, {
        'Accept': ['application/json']
    });
}
/**
 * Rebuild the archive catalog from disk
 */
export function rebuild(): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.archive/cluster/archives/catalog/rebuild', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Check the on-disk availability of the given catalog entry
 */
export function checkAvailability(entryId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.archive/cluster/archives/catalog/${entryId}/available`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete the archive for the given id
 */
export function remove(archiveId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.archive/cluster/archives/${archiveId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Restore the given archive
 */
export function restore(archiveId: string): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.archive/cluster/archives/${archiveId}/restore`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Archive the given index
 */
export function run(indexName: string, index_action: 'NONE' | 'CLOSE' | 'DELETE' = 'NONE'): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.archive/cluster/archives/${indexName}`, null, { 'index_action': index_action }, {
        'Accept': ['application/json']
    });
}
