import * as React from 'react';

import { LinkContainer } from 'components/common/router';
import { Button } from 'components/bootstrap';
import DataWarehouseRoutes from 'data-warehouse/Routes';

type Props = {
  streamId: string,
}

const PreviewWarehouseLogsButton = ({ streamId }: Props) => (
  <LinkContainer to={`${DataWarehouseRoutes.SEARCH}?streams=${streamId}`}>
    <Button bsSize="xs" title="Preview warehouse logs">
      Preview logs
    </Button>
  </LinkContainer>
);

export default PreviewWarehouseLogsButton;
