import __request__ from 'routing/request';
interface EventTypeCategory {
    readonly default_indicator: string;
    readonly sub_category: string;
    readonly summary_template: string;
    readonly indicator_template: string;
    readonly description: string;
    readonly id: string;
    readonly category: string;
    readonly title: string;
}
interface EventTypeResponse {
    readonly summary: string;
    readonly indicatorTemplate: IndicatorTemplateResponse;
    readonly defaultIndicator: string;
}
interface EventTypeResponseMapMap {
    readonly [_key: string]: EventTypeResponseMap;
}
interface EventTypeCategoryRequest {
    readonly default_indicator: string;
    readonly sub_category: string;
    readonly summary_template: string;
    readonly indicator_template: string;
    readonly description: string;
    readonly category: string;
    readonly title: string;
}
interface IndicatorTemplateResponse {
    readonly indicator: string;
    readonly default: string[];
    readonly gray: string[];
    readonly success: string[];
    readonly warning: string[];
    readonly defaultIndicator: string;
    readonly danger: string[];
    readonly info: string[];
    readonly primary: string[];
}
interface EventTypeResponseMap {
    readonly [_key: string]: EventTypeResponse;
}
interface PaginatedResponse_EventTypeCategory {
    readonly [_key: string]: Object;
}
/**
 * Create a new EventTypeCategory
 * @param category category
 */
export function create(category?: EventTypeCategoryRequest): Promise<EventTypeCategory> {
    return __request__('POST', '/plugins/org.graylog.plugins.contentui/event_type_category', category, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all Event Type Categories
 */
export function getAll(page: number = 1, page_size: number = 50): Promise<PaginatedResponse_EventTypeCategory> {
    return __request__('GET', '/plugins/org.graylog.plugins.contentui/event_type_category', null, { 'page': page, 'page_size': page_size }, {
        'Accept': ['application/json']
    });
}
/**
 * Get a Mapping of Event Type Categories as required by the UI to produce the Message Summaries
 */
export function getEventTypeCategoryUiMap(): Promise<EventTypeResponseMapMap> {
    return __request__('GET', '/plugins/org.graylog.plugins.contentui/event_type_category/ui_mapping', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get Event Type Category By ID
 * @param id id
 */
export function getById(id?: string): Promise<EventTypeCategory> {
    return __request__('GET', `/plugins/org.graylog.plugins.contentui/event_type_category/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an existing EventTypeCategory
 * @param category category
 * @param id id
 */
export function update(category?: EventTypeCategoryRequest, id?: string): Promise<EventTypeCategory> {
    return __request__('PUT', `/plugins/org.graylog.plugins.contentui/event_type_category/${id}`, category, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an existing Event Type Category By ID
 * @param id id
 */
export function remove(id?: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.contentui/event_type_category/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
