import { useQuery } from '@tanstack/react-query';

import type { Attribute, SearchParams, Attributes } from 'stores/PaginationTypes';
import type { DataWarehouse } from 'data-warehouse/Types';
import PaginationURL from 'util/PaginationURL';
import { DATA_WAREHOUSE_API_ROUTES } from 'data-warehouse/Constants';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { defaultOnError } from 'util/conditional/onError';

const INITIAL_DATA = {
  pagination: { total: 0 },
  list: [],
  attributes: [],
};

const refetchInterval = 5000;

type Options = {
  enabled: boolean,
}

type PaginatedResponse = {
  pagination: {
    count: number,
    total: number,
    page: number,
    per_page: number,
  },
  query: string,
  attributes: Attributes,
  elements: Array<DataWarehouse>,
};

export const fetchDataWarehouses = (searchParams: SearchParams) => {
  const url = PaginationURL(
    DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.paginated().url,
    searchParams.page,
    searchParams.pageSize,
    searchParams.query,
    {
      sort: searchParams.sort.attributeId,
      order: searchParams.sort.direction,
    },
  );

  return fetch('GET', qualifyUrl(url)).then((response: PaginatedResponse) => {
    const {
      elements,
      query,
      attributes,
      pagination: {
        count,
        total,
        page,
        per_page: perPage,
      },
    } = response;

    return {
      list: elements,
      attributes,
      pagination: {
        count,
        total,
        page,
        perPage,
        query,
      },
    };
  });
};

export const loadStreamsDatawarehouseOptions = ({ page, perPage, query }) => fetchDataWarehouses({
  page,
  pageSize: perPage,
  query,
  sort: { attributeId: 'last_known_stream_title', direction: 'asc' },
}).then(({ list, pagination }) => ({
  list: list.map(({ last_known_stream_title, stream_id }) => ({ label: last_known_stream_title, value: stream_id })),
  pagination: { page: pagination.page, perPage: pagination.perPage, query: pagination.query },
  total: pagination.total,
}));

export const keyFn = (searchParams: SearchParams) => ['data-warehouse', 'overview', searchParams];

const useDataWarehouses = (searchParams: SearchParams, { enabled }: Options = { enabled: true }): {
  data: {
    list: Array<DataWarehouse>,
    pagination: { total: number }
    attributes: Array<Attribute>
  },
  refetch: () => void,
  isInitialLoading: boolean,
} => {
  const { data, refetch, isInitialLoading } = useQuery(
    keyFn(searchParams),
    () => defaultOnError(fetchDataWarehouses(searchParams), 'Loading Data Warehouse failed with status', 'Could not load Data Warehouse'),
    {
      keepPreviousData: true,
      refetchInterval,
      notifyOnChangeProps: ['data', 'error'],
      enabled,
    },
  );

  return ({
    data: data ?? INITIAL_DATA,
    refetch,
    isInitialLoading,
  });
};

export default useDataWarehouses;
