import __request__ from 'routing/request';
type MappedFieldTypeDTOArray = MappedFieldTypeDTO[];
interface TimeRange {
    readonly type: string;
}
interface Type {
    readonly index_names: string[];
    readonly type: string;
    readonly properties: string[];
}
interface MappedFieldTypeDTO {
    readonly unit: UnitId;
    readonly name: string;
    readonly type: Type;
}
interface UnitId {
    readonly abbrev: string;
    readonly unit_type: string;
}
interface FieldTypesForStreamsRequest {
    readonly timerange: TimeRange;
    readonly streams: string[];
}
/**
 * Retrieve the list of all fields present in the system
 */
export function allFieldTypes(): Promise<MappedFieldTypeDTOArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.views/views/fields', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve the field list of a given set of streams
 */
export function byStreams(JSONbody: FieldTypesForStreamsRequest): Promise<MappedFieldTypeDTOArray> {
    return __request__('POST', '/plugins/org.graylog.plugins.views/views/fields', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Trigger a full refresh of field types
 */
export function triggerFieldTypePolling(): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.views/views/fields/poll', null, {}, {
        'Accept': ['application/json']
    });
}
