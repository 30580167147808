import { useQuery } from '@tanstack/react-query';
import { create, windowScheduler, keyResolver } from '@yornaath/batshit';

import { DataWarehouse } from '@graylog/enterprise-api';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';

import { DATA_WAREHOUSE_API_ROUTES } from '../Constants';
import type { StreamDataWarehouseConfig } from '../Types';

const dataWarehouseStatusses = create({
  fetcher: (ids: Array<string>) => DataWarehouse.getStreamConfigs({ stream_ids: ids }),
  resolver: keyResolver('stream_id'),
  scheduler: windowScheduler(10),
});
export const fetchStreamDataWarehouseStatus = (streamId: string) => fetch('GET', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.stream_data_warehouse_config(streamId).url));

const useStreamDataWarehouseStatus = (streamId: string): {
  data: StreamDataWarehouseConfig,
  refetch: () => void,
  isFetching: boolean,
  isError: boolean,
} => {
  const { data, refetch, isFetching, isError } = useQuery<StreamDataWarehouseConfig, Error>(
    ['data-warehouse-config', streamId, 'enabled'],
    () => dataWarehouseStatusses.fetch(streamId),
    {
      keepPreviousData: true,
      retry: 1,
    },
  );

  return {
    data,
    refetch,
    isFetching,
    isError,
  };
};

export default useStreamDataWarehouseStatus;
