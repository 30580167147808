import __request__ from 'routing/request';
interface TeamSyncBackendDTO {
    readonly selection_type: 'all' | 'include' | 'exclude';
    readonly selection: string[];
    readonly auth_service_backend_id: string;
    readonly default_roles: string[];
    readonly id: string;
    readonly config: TeamSyncBackendConfig;
}
interface TeamSyncBackendConfig {
    readonly type: string;
}
interface PaginatedResponse_TeamDTO {
    readonly [_key: string]: Object;
}
/**
 * Returns the team sync backend for the given authentication service
 */
export function get(authServiceId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.security/team-sync/services/${authServiceId}/backend`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Updates the team sync backend configuration for the given authentication service
 */
export function update(authServiceId: string, JSONbody: TeamSyncBackendDTO): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.security/team-sync/services/${authServiceId}/backend`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Deletes the team sync backend configuration for the given authentication service
 */
export function remove(authServiceId: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.security/team-sync/services/${authServiceId}/backend`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Creates a new team sync backend configuration for the given authentication service
 */
export function create(authServiceId: string, JSONbody: TeamSyncBackendDTO): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.security/team-sync/services/${authServiceId}/backend`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get paginated teams for the given authentication service backend
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getUsers(sort: 'name' | 'description' = 'name', authServiceId: string, page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_TeamDTO> {
    return __request__('GET', `/plugins/org.graylog.plugins.security/team-sync/services/${authServiceId}/backend/teams`, null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
