import __request__ from 'routing/request';
interface FailureCountResponseMap {
    readonly [_key: string]: FailureCountResponse;
}
interface FailureCountResponse {
    readonly count: number;
    readonly source: 'elasticsearch' | 'mongodb';
    readonly enabled: boolean;
}
/**
 * Count of failed message operations since the last 24 hours
 */
export function count(): Promise<FailureCountResponseMap> {
    return __request__('GET', '/plugins/org.graylog.plugins.failure/failures/count', null, {}, {
        'Accept': ['application/json']
    });
}
