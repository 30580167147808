import __request__ from 'routing/request';
type CategoryArray = Category[];
interface Category {
    readonly id: string;
    readonly category: string;
}
/**
 * Get a list of asset categories
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function listStatuses(sort: 'category' = 'category', page: number = 1, per_page: number = 15, query?: string, direction: 'asc' | 'desc' = 'asc'): Promise<unknown[]> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/assets/categories', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new asset category
 */
export function create(Category?: string): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/assets/categories', Category, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all possible asset categories
 */
export function getAll(): Promise<CategoryArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/assets/categories/all', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an asset category
 */
export function update(id: string, Category?: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/assets/categories/${id}`, Category, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an asset category
 */
export function remove(id: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/assets/categories/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
