import __request__ from 'routing/request';
interface PivotResult {
    readonly total: number;
    readonly effective_timerange: AbsoluteRange;
    readonly name: string;
    readonly id: string;
    readonly rows: Row[];
    readonly type: string;
}
interface AbsoluteRange {
    readonly from: string;
    readonly to: string;
    readonly type: string;
}
interface Value {
    readonly source: string;
    readonly value: Object;
    readonly key: string[];
    readonly rollup: boolean;
}
interface Row {
    readonly values: Value[];
    readonly source: string;
    readonly key: string[];
}
/**
 * Export widget data
 * @param filename filename
 */
export function exportData(arg0?: PivotResult, filename?: string): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.views/views/search/pivot/export/${filename}`, arg0, {}, {
        'Accept': [
            'text/csv',
            'application/json',
            'application/yaml',
            'application/xml',
            'application/vnd.ms-excel'
        ]
    });
}
