import __request__ from 'routing/request';
interface AIClusterConfig {
    readonly investigations_ai_reports_enabled: boolean;
}
/**
 * Gets AI configuration
 */
export function aiClusterConfig(): Promise<AIClusterConfig> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/ai/config', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Enables AI Investigations Reports
 */
export function enableInvestigationsAIReport(): Promise<void> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/ai/config/investigations_ai_reports_enabled', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Disables AI Investigations Reports
 */
export function disableInvestigationsAIReport(): Promise<void> {
    return __request__('DELETE', '/plugins/org.graylog.plugins.securityapp/ai/config/investigations_ai_reports_enabled', null, {}, {
        'Accept': ['application/json']
    });
}
