import __request__ from 'routing/request';
interface PipelineConnections {
    readonly stream_id: string;
    readonly id: string;
    readonly pipeline_ids: string[];
}
type PipelineConnectionsArray = PipelineConnections[];
interface PipelineReverseConnections {
    readonly stream_ids: string[];
    readonly pipeline_id: string;
}
/**
 * Get all pipeline connections
 */
export function getAll(): Promise<PipelineConnectionsArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.pipelineprocessor/system/pipelines/connections', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Connect streams to a processing pipeline
 */
export function connectStreams(Jsonbody: PipelineReverseConnections): Promise<PipelineConnectionsArray> {
    return __request__('POST', '/plugins/org.graylog.plugins.pipelineprocessor/system/pipelines/connections/to_pipeline', Jsonbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Connect processing pipelines to a stream
 */
export function connectPipelines(Jsonbody: PipelineConnections): Promise<PipelineConnections> {
    return __request__('POST', '/plugins/org.graylog.plugins.pipelineprocessor/system/pipelines/connections/to_stream', Jsonbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get pipeline connections for the given stream
 */
export function getPipelinesForStream(streamId?: string): Promise<PipelineConnections> {
    return __request__('GET', `/plugins/org.graylog.plugins.pipelineprocessor/system/pipelines/connections/${streamId}`, null, {}, {
        'Accept': ['application/json']
    });
}
