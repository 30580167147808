import { useQuery } from '@tanstack/react-query';
import { SearchFiltersUsage } from '@graylog/enterprise-api';

import type { SearchFilterUsagesSummary } from 'search-filter/types';
import { defaultOnError } from 'util/conditional/onError';

const USER_FILTER_SUMMARY_QUERY_KEY = 'user_filter_summary_query_key';

type Result = {
  data: SearchFilterUsagesSummary,
  isFetching: boolean,
  refetch?: () => Promise<{ data: SearchFilterUsagesSummary, isFetching: boolean }>
}

const useSearchFilterUsagesSummaryQuery = (filterId: string, options?: { enabled: boolean }): Result => useQuery(
  [USER_FILTER_SUMMARY_QUERY_KEY, filterId, 'summary'],
  () => defaultOnError(SearchFiltersUsage.get(filterId), `Loading search filter usages summary for filter with id ${filterId} failed with status`, 'Could not load search filter usages summary'),
  {
    keepPreviousData: true,
    initialData: {
      summary: [],
    },
    ...options,
  });

export default useSearchFilterUsagesSummaryQuery;
