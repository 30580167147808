import __request__ from 'routing/request';
interface RegionsResponse {
    readonly total: number;
    readonly regions: AWSRegion[];
}
interface AWSRegion {
    readonly label: string;
    readonly value: string;
}
/**
 * Get all available AWS regions
 */
export function getAwsRegions(): Promise<RegionsResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.archive/backends/s3/regions', null, {}, {
        'Accept': ['application/json']
    });
}
