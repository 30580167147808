import __request__ from 'routing/request';
interface MongoDBDataAdapterEntry {
    readonly values: string[];
    readonly expire_after: string;
    readonly id: string;
    readonly data_adapter_id: string;
    readonly ttl: number;
    readonly key: string;
    readonly ttl_unit: 'NANOSECONDS' | 'MICROSECONDS' | 'MILLISECONDS' | 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS';
}
interface PaginatedResponse_MongoDBDataAdapterEntry {
    readonly [_key: string]: Object;
}
interface MongoDBDataAdapterStats {
    readonly entries_count: number;
}
/**
 * Get stats for data adapter
 */
export function stats(adapterId: string): Promise<MongoDBDataAdapterStats> {
    return __request__('GET', `/plugins/org.graylog.plugins.lookup/lookup/adapters/mongodb/stats/${adapterId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete all entries for this MongoDB data adapter
 */
export function deleteAllByDataAdapterId(adapterId: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.lookup/lookup/adapters/mongodb/${adapterId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List all entries of a MongoDB data adapter
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function get(sort: 'id' | 'title' | 'created_at' | 'key' = 'key', adapterId?: string, page: number = 1, per_page: number = 50, order: 'asc' | 'desc' = 'asc', query?: string): Promise<PaginatedResponse_MongoDBDataAdapterEntry> {
    return __request__('GET', `/plugins/org.graylog.plugins.lookup/lookup/adapters/mongodb/${adapterId}`, null, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order, 'query': query }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a MongoDB lookup entry
 */
export function create(JSONBody: MongoDBDataAdapterEntry, adapterId?: string): Promise<MongoDBDataAdapterEntry> {
    return __request__('POST', `/plugins/org.graylog.plugins.lookup/lookup/adapters/mongodb/${adapterId}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a MongoDB lookup entry
 */
export function update(adapterId: string, entryId: string, JSONBody: MongoDBDataAdapterEntry): Promise<MongoDBDataAdapterEntry> {
    return __request__('PUT', `/plugins/org.graylog.plugins.lookup/lookup/adapters/mongodb/${adapterId}/${entryId}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a MongoDB data adapter entry
 */
export function remove(adapterId: string, entryId: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.lookup/lookup/adapters/mongodb/${adapterId}/${entryId}`, null, {}, {
        'Accept': ['application/json']
    });
}
