import * as React from 'react';
import styled, { css } from 'styled-components';

import { Row } from 'security-app/components/common/FormBaseStyles';

const NavTab = styled.span<{ $active: boolean }>(({ $active, theme }) => css`
  cursor: pointer;
  color: ${$active ? `${theme.colors.global.textDefault}` : 'grey'};
  padding: 0.5rem;
  border-bottom: 1px solid ${$active ? `${theme.colors.button.primary.background}` : 'transparent'};

  &:hover {
    color: ${theme.colors.global.textDefault};
  }
`);

export type SmallTabType<T = unknown> = {
  id: string,
  Label: React.FC,
  Component?: React.FC,
  type?: T,
};

type Props = {
  tabs: SmallTabType[],
  activeTab: number | string,
  onClick: (tabId: number | string) => void,
};

function SmallNavTabs({ tabs, activeTab, onClick }: Props) {
  return (
    <Row>
      {tabs.map((tab) => (
        <NavTab key={tab.id}
                $active={activeTab === tab.id}
                onClick={() => onClick(tab.id)}
                role="tab"
                aria-label={tab.id}>
          <tab.Label />
        </NavTab>
      ))}
    </Row>
  );
}

export default SmallNavTabs;
