import React, { useMemo } from 'react';

import EventDetailsTable from 'components/events/events/EventDetailsTable';
import type { SecurityEventAPIType, SecurityEventEntity } from 'security-app/hooks/api/securityEventsAPI.types';
import columnRenderers from 'security-app/components/SecurityEvents/Alerts/ColumnRenderers';

type Props = {
  attributesList: Array<{ id: string, title: string}>,
  event: SecurityEventAPIType,
}

const SecurityEventDetailsTable = ({ event, attributesList }: Props) => {
  const { attributes: attributesRenderers } = columnRenderers;
  const eventEntity: SecurityEventEntity = useMemo(() => {
    const { event: generalEvent, ...rest } = event;

    return ({ ...rest, ...generalEvent });
  }, [event]);

  return (
    <EventDetailsTable<SecurityEventEntity> event={eventEntity} attributesRenderers={attributesRenderers} attributesList={attributesList} />
  );
};

export default SecurityEventDetailsTable;
