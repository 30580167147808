import { useQuery } from '@tanstack/react-query';
import { DataWarehouseQuery } from '@graylog/enterprise-api';

import type { TimeRange } from 'views/logic/queries/Query';
import type FetchError from 'logic/errors/FetchError';
import { defaultCompare } from 'logic/DefaultCompare';
import type { LogViewMessage } from 'logview/types';

export const PAGE_SIZE = 100;

export const TIMEOUT = 60;

type SearchResultResponse = {
  messages: Array<{ message: { [fieldName: string]: unknown } }>,
};

const isLogViewMessage = (message: { message: { [fieldName: string]: unknown } }): message is LogViewMessage => (
  '_id' in message.message && 'timestamp' in message.message
);

const formatResponse = (
  response: SearchResultResponse,
): Array<LogViewMessage> => response.messages?.sort(
  ({ message: { timestamp: timestamp1 } },
    { message: { timestamp: timestamp2 } }) => defaultCompare(timestamp2, timestamp1),
).map(({ message }) => {
  const { id, timestamp, ...rest } = message;
  const logViewMessage = ({ message: { ...rest, _id: id, timestamp } });

  if (!isLogViewMessage(logViewMessage)) {
    throw Error('A message is missing the id or timestamp attribute.');
  }

  return logViewMessage;
});

export const fetchSearchResults = (
  { fields, stream, timerange, searchAfter }:
  { fields: Array<string>; stream: string, timerange: TimeRange, searchAfter?: { id: string, timestamp: string }
}) => DataWarehouseQuery.query(stream, PAGE_SIZE, TIMEOUT, {
  timerange,
  fields,
  search_after: searchAfter,
  inclusion_type: 'BOTH',
  field_filters: [],
}).then((result) => formatResponse(result));

const useSearchResults = (
  searchFilters: { stream: string, timerange: TimeRange },
  fields: Array<string>,
  enabled: boolean,
): {
  error: FetchError,
  data: Array<LogViewMessage>,
  refetch: () => void,
  isFetching: boolean
} => useQuery({
  queryKey: ['data-warehouse', searchFilters],
  queryFn: () => fetchSearchResults({
    fields,
    stream: searchFilters.stream,
    timerange: searchFilters.timerange,
  }),
  keepPreviousData: true,
  retry: 0,
  enabled,
});

export default useSearchResults;
