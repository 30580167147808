import * as React from 'react';

import { Badge } from 'common/components';
import { useGetPriorities } from 'security-app/hooks/useInvestigationsAPI';
import type { PriorityAPIType } from 'security-app/hooks/api/investigationsAPI.types';

const BADGE_COLOR_BY_PRIORITY = {
  1: 'danger',
  2: 'danger',
  3: 'warning',
  4: 'default',
};

type Props = {
  priorityValue: number,
};

function PriorityCell({ priorityValue }: Props) {
  const { priorities } = useGetPriorities(true);

  const priorityText = React.useMemo(() => (
    priorities.find((priority: PriorityAPIType) => priority.priority === priorityValue)?.text || null
  ), [priorities, priorityValue]);

  return (
    <Badge size="sm" variant={BADGE_COLOR_BY_PRIORITY[priorityValue]}>
      {priorityText}
    </Badge>
  );
}

export default PriorityCell;
