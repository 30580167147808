import __request__ from 'routing/request';
interface AssetSourceBackendDto {
    readonly description: string;
    readonly id: string;
    readonly title: string;
    readonly config: AssetSourceBackendConfig;
}
interface PaginatedResponse_AssetSourceBackendDto {
    readonly [_key: string]: Object;
}
interface BulkAssetSourceBackendTestResponse {
    readonly results: {
        readonly [_key: string]: boolean;
    };
}
interface BulkAssetSourceBackendRequest {
    readonly ids: string[];
}
interface AssetSourceBackendConfig {
    readonly type: string;
}
/**
 * Get a paginated list of existing asset sources
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function listAssets(sort: 'title' = 'title', page: number = 1, per_page: number = 15, query?: string, filters?: string, direction: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_AssetSourceBackendDto> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/assets/sources', null, { 'page': page, 'per_page': per_page, 'query': query, 'filters': filters, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new asset source
 */
export function create(JSONBody?: AssetSourceBackendDto): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/assets/sources', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Test multiple asset source connections
 */
export function bulkTestConnections(JSONBody?: BulkAssetSourceBackendRequest): Promise<BulkAssetSourceBackendTestResponse> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/assets/sources/bulk/test', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a list of allowed MS365 source deployment types
 */
export function listMs356DeploymentTypes(): Promise<unknown> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/assets/sources/ms365-deployment-types', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Test asset source connection
 */
export function testNewConnection(JSONBody?: AssetSourceBackendDto): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/assets/sources/test', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an asset source
 */
export function update(sourceId: string, JSONBody?: AssetSourceBackendDto): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an asset source
 */
export function remove(sourceId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single existing asset source
 */
export function getSource(sourceId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Test asset source connection
 */
export function testExistingConnection(sourceId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}/test`, null, {}, {
        'Accept': ['application/json']
    });
}
