import __request__ from 'routing/request';
interface Metric {
    readonly name: string;
    readonly inc: number;
}
interface AuthMetricsRequest {
    readonly metrics: Metric[];
}
/**
 * Post auth metrics
 */
export function authMetrics(JSONBody?: AuthMetricsRequest): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.cloud/auth_metrics', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
