import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Icon, Spinner, Select } from 'components/common';
import { useStore } from 'stores/connect';
import { Input, Button } from 'components/bootstrap';
import type { InputProfile } from 'forwarder/Types';
import type { InputProfilesStoreState } from 'forwarder/stores/InputProfilesStore';
import InputProfilesStore, { InputProfilesActions } from 'forwarder/stores/InputProfilesStore';

const StyledIcon = styled(Icon)`
  margin-right: 0.5em;
`;

const SelectWrapper = styled('div')`
  margin: 1em 0;
`;

type Props = {
  onInputProfileSelect: (inputProfile: InputProfile) => void,
  maxRadio?: number,
};

const InputProfileSelectionList = ({ onInputProfileSelect, maxRadio = 6 }: Props) => {
  const [selectedInputProfile, setSelectedInputProfile] = useState<InputProfile>();
  const { all: inputProfiles } = useStore<InputProfilesStoreState>(InputProfilesStore);

  useEffect(() => {
    InputProfilesActions.listAll();
  }, []);

  if (!inputProfiles) {
    return <div><Spinner text="Loading Input Profiles..." /></div>;
  }

  const _selectProfile = (id: string) => {
    const selectedProfile = inputProfiles.find((inputProfile) => inputProfile.id === id);

    if (selectedProfile === undefined) {
      throw new Error('Selected Input Profile is not in the list of Input Profiles.');
    }

    setSelectedInputProfile(selectedProfile);
  };

  const handleRadioSelection = (event) => {
    const { value: id } = event.target;
    _selectProfile(id);
  };

  const handleSelectChange = _selectProfile;

  const handleInputProfileSelect = (event) => {
    event.preventDefault();

    if (selectedInputProfile) {
      onInputProfileSelect(selectedInputProfile);
    }
  };

  return (
    <form onSubmit={handleInputProfileSelect}>
      <div>
        {/* Show loader when no input profiles are available */}
        {inputProfiles.length === 0 && (
          <p><StyledIcon name="info" /> There are no Input Profiles yet.</p>
        )}

        {/* Show radio buttons when not exceeding maximum radio buttons */}
        {inputProfiles.length > 0 && inputProfiles.length <= maxRadio && (
          inputProfiles.map((inputProfile) => (
            <Input key={inputProfile.id}
                   id={inputProfile.id}
                   type="radio"
                   name="inputProfile"
                   value={inputProfile.id}
                   label={inputProfile.title}
                   onChange={handleRadioSelection} />
          ))
        )}

        {/* Show <Select /> when too many options */}
        {inputProfiles.length > 0 && inputProfiles.length > maxRadio && (
          <SelectWrapper>
            <Select name="inputProfile"
                    placeholder="Select Input Profile..."
                    onChange={handleSelectChange}
                    options={inputProfiles.map((inputProfile) => ({ label: inputProfile.title, value: inputProfile.id }))} />
          </SelectWrapper>
        )}
      </div>
      <Button type="submit" bsStyle="primary" disabled={selectedInputProfile === undefined}>
        Finish configuration
      </Button>
    </form>
  );
};

export default InputProfileSelectionList;
