import __request__ from 'routing/request';
interface BackendPage {
    readonly per_page: number;
    readonly total: number;
    readonly query: string;
    readonly count: number;
    readonly page: number;
    readonly backends: ArchiveBackendConfig[];
}
interface ArchiveBackendSettingsDTO {
    readonly type: string;
}
interface OutputTemplateTestResult {
    readonly template: string;
    readonly result: string;
}
interface ArchiveBackendConfig {
    readonly settings: ArchiveBackendSettingsDTO;
    readonly description: string;
    readonly id: string;
    readonly title: string;
}
/**
 * Create new backend config
 */
export function createConfig(config: ArchiveBackendConfig): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.archive/backends', config, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get backend configurations
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getConfigs(sort: 'title' | 'created_at' = 'created_at', page: number = 1, per_page: number = 50, order: 'asc' | ' desc' = 'asc', query?: string): Promise<BackendPage> {
    return __request__('GET', '/plugins/org.graylog.plugins.archive/backends', null, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order, 'query': query }, {
        'Accept': ['application/json']
    });
}
/**
 * Test if given output template string is valid
 */
export function testOutputTemplate(template?: string): Promise<OutputTemplateTestResult> {
    return __request__('GET', '/plugins/org.graylog.plugins.archive/backends/test-output-template', null, { 'template': template }, {
        'Accept': ['application/json']
    });
}
/**
 * Update backend config
 */
export function updateConfig(config: ArchiveBackendConfig, backendId: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.archive/backends/${backendId}`, config, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get backend configuration
 */
export function getConfig(backendId?: string): Promise<ArchiveBackendConfig> {
    return __request__('GET', `/plugins/org.graylog.plugins.archive/backends/${backendId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete backend configuration
 */
export function remove(id: string, delete_archives: boolean = false): Promise<ArchiveBackendConfig> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.archive/backends/${id}`, null, { 'delete_archives': delete_archives }, {
        'Accept': ['application/json']
    });
}
