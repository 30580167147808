import * as React from 'react';
import styled from 'styled-components';
import Immutable from 'immutable';
import { useCallback } from 'react';

import FieldSelect from 'data-warehouse/search/FieldSelect';
import StickyBottomActions from 'views/components/widgets/StickyBottomActions';
import SaveOrCancelButtons from 'views/components/widgets/SaveOrCancelButtons';
import DescriptionBox from 'views/components/aggregationbuilder/DescriptionBox';
import FieldsConfiguration from 'views/components/widgets/FieldsConfiguration';
import type LogViewWidgetConfig from 'logview/logic/LogViewWidgetConfig';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';

import DataWarehouseFieldsContext from './DataWarehouseFieldsContext';

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 15px;
  display: flex;
`;

const Col = styled.div`
  height: 100%;
`;

const ControlsCol = styled(Col)`
  min-width: 315px;
  max-width: 500px;
  flex: 1.2;
  padding-right: 5px;
  margin-right: 10px;
`;

const LogViewCol = styled(Col)`
  flex: 3;
  overflow-y: hidden;
`;

type Props = React.PropsWithChildren<{
  config: LogViewWidgetConfig,
  onCancel: () => void,
  onChange: (newConfig: LogViewWidgetConfig) => void,
}>

const DataWarehouseLogViewEdit = ({ children, config, onChange, onCancel }: Props) => {
  const onChangeFields = useCallback((newFields: Array<string>) => {
    onChange(config.toBuilder().fields(Immutable.OrderedSet(newFields)).build());
  }, [config, onChange]);

  return (
    <DataWarehouseFieldsContext.Provider value={Immutable.List<FieldTypeMapping>(config.fields)}>
      <Container>
        <ControlsCol>
          <StickyBottomActions actions={<SaveOrCancelButtons onCancel={onCancel} />}
                               alignActionsAtBottom>
            <DescriptionBox description="Field Selection and Order">
              <FieldsConfiguration onChange={onChangeFields}
                                   fieldSelect={FieldSelect}
                                   selectSize="normal"
                                   selectedFields={config.fields.toArray()}
                                   menuPortalTarget={document.body} />
            </DescriptionBox>
          </StickyBottomActions>
        </ControlsCol>
        <LogViewCol>
          {children}
        </LogViewCol>
      </Container>
    </DataWarehouseFieldsContext.Provider>
  );
};

export default DataWarehouseLogViewEdit;
