import * as React from 'react';

import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { getPathnameWithoutId } from 'util/URLUtils';
import { Button, DeleteMenuItem } from 'components/bootstrap';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';

type Props = {
  onClick: () => void,
  buttonType?: 'menu' | 'button',
};

const RetrievalArchiveAction = ({
  onClick,
  buttonType = 'menu',
}: Props) => {
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();

  const onOpenDeleteConfirm = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.DATAWAREHOUSE.DATA_DELETION_OPENED, {
      app_pathname: getPathnameWithoutId(pathname),
    });

    onClick();
  };

  const isMenuButton = buttonType === 'menu';
  const ItemComponent = isMenuButton ? DeleteMenuItem : Button;
  const buttonProps = isMenuButton ? { } : { bsStyle: 'danger' as const };

  return (
    <ItemComponent onClick={onOpenDeleteConfirm} bsSize="xsmall" {...buttonProps}>
      Delete
    </ItemComponent>
  );
};

export default RetrievalArchiveAction;
