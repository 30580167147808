import React, { useState, useCallback } from 'react';
import { styled, css } from 'styled-components';

import { Row, Alert } from 'components/bootstrap';
import Store from 'logic/local-storage/Store';

const HIDE_DW_SEARCH_EXPLANATION_KEY = 'hide-data-warehouse-search-explanation';

const Container = styled(Alert)(({ theme }) => css`
  margin-top: 0;
  margin-bottom: ${theme.spacings.xs};
`);

const SearchFeatureExplanation = () => {
  const [hideExplanation, setHideExplanation] = useState(Store.get(HIDE_DW_SEARCH_EXPLANATION_KEY) === 'true');

  const hideFeatureExplanation = useCallback(() => {
    Store.set(HIDE_DW_SEARCH_EXPLANATION_KEY, 'true');
    setHideExplanation(true);
  }, []);

  if (hideExplanation) {
    return null;
  }

  return (
    <Row>
      <Container onDismiss={hideFeatureExplanation}>
        This section provides a high-level view of logs stored in your Data Warehouse. The functionality here is limited. For more detailed queries, add the relevant data source to the regular processing pipeline.
      </Container>
    </Row>
  );
};

export default SearchFeatureExplanation;
