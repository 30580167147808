import __request__ from 'routing/request';
interface ReportingSystemConfiguration {
    readonly maximum_history_size: number;
}
/**
 * Get Reporting configuration
 */
export function read(): Promise<ReportingSystemConfiguration> {
    return __request__('GET', '/plugins/org.graylog.plugins.report/config', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update Reporting configuration
 */
export function edit(body: ReportingSystemConfiguration): Promise<ReportingSystemConfiguration> {
    return __request__('PUT', '/plugins/org.graylog.plugins.report/config', body, {}, {
        'Accept': ['application/json']
    });
}
