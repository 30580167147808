import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import { fetchDataWarehouses } from 'data-warehouse/hooks/useDataWarehouses';
import { FormGroup } from 'components/bootstrap';
import PaginatedSelect from 'components/common/Select/PaginatedSelect';

const Container = styled(FormGroup)`
  flex: 1;
  grid-area: streams;
`;

const placeholder = 'Select the stream you want to preview logs for';

const StreamFilter = () => {
  const loadStreams = ({ page, perPage, query }) => fetchDataWarehouses({
    page,
    pageSize: perPage,
    query,
    sort: { attributeId: 'last_known_stream_title', direction: 'asc' },
  }).then(({ list, pagination }) => ({
    list: list.map(({ last_known_stream_title, stream_id }) => ({ label: last_known_stream_title, value: stream_id })),
    pagination: { page: pagination.page, perPage: pagination.perPage, query: pagination.query },
    total: pagination.total,
  }));

  return (
    <Field name="stream">
      {({ field: { name, value, onChange }, meta: { error } }) => (
        <Container data-testid="streams-filter"
                   title={placeholder}
                   validationState={error ? 'error' : undefined}
                   bsClass="no-bm">
          <PaginatedSelect placeholder={placeholder}
                           displayKey="key"
                           inputId="streams-filter"
                           value={value}
                           onLoadOptions={loadStreams}
                           multi={false}
                           onChange={(newStream: string) => {
                             onChange({
                               target: { value: newStream, name },
                             });
                           }} />
        </Container>
      )}
    </Field>
  );
};

export default StreamFilter;
