import __request__ from 'routing/request';
interface Binding {
    readonly type: string;
}
interface Parameter {
    readonly name: string;
    readonly data_type: string;
    readonly binding: Binding;
    readonly description: string;
    readonly optional: boolean;
    readonly default_value: Object;
    readonly title: string;
    readonly type: string;
}
type ViewParameterSummaryDTOArray = ViewParameterSummaryDTO[];
interface ViewParameterSummaryDTO {
    readonly summary: string;
    readonly description: string;
    readonly id: string;
    readonly type: 'SEARCH' | 'DASHBOARD';
    readonly title: string;
    readonly parameters: Parameter[];
}
/**
 * Get all views that match given parameter value
 */
export function forParameter(): Promise<ViewParameterSummaryDTOArray> {
    return __request__('POST', '/plugins/org.graylog.plugins.views/views/forValue', null, {}, {
        'Accept': ['application/json']
    });
}
