import * as React from 'react';
import styled from 'styled-components';

import { PADDING_BY_SIZE, getBGColorByVariant, getTextColorByVariant } from './utils';
import type { BadgeStyles, BadgeSize } from './types';

const BadgeContainer = styled.div<{ $size: string, $variant: string, $width?: string }>`
  padding: ${({ theme, $size }) => theme.spacings[PADDING_BY_SIZE[$size].topBottom]} ${({ theme, $size }) => theme.spacings[PADDING_BY_SIZE[$size].leftRight]};
  background-color: ${({ theme, $variant }) => getBGColorByVariant(theme, $variant)};
  border-radius: ${({ theme }) => theme.spacings.xs};
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${({ $width }) => $width || 'auto'};
`;

const BadgeText = styled.small<{ $size: string, $variant: string }>`
  text-transform: uppercase;
  color: ${({ theme, $variant }) => getTextColorByVariant(theme, $variant)};
  text-align: center;
  font-weight: bold;
`;

type Props = {
  children: string | number,
  variant?: BadgeStyles,
  size?: BadgeSize,
};

function Badge({ children, variant = 'default', size = 'sm' }: Props) {
  if (!children && typeof children !== 'number') return null;

  return (
    <BadgeContainer role="status" aria-label={children.toString()} $size={size} $variant={variant}>
      <BadgeText $size={size} $variant={variant}>{children}</BadgeText>
    </BadgeContainer>
  );
}

export default Badge;
