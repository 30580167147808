import __request__ from 'routing/request';
interface ConfigurationVariable {
    readonly name: string;
    readonly description: string;
    readonly id: string;
    readonly content: string;
}
type ConfigurationVariableArray = ConfigurationVariable[];
type ConfigurationArray = Configuration[];
interface Configuration {
    readonly template: string;
    readonly color: string;
    readonly collector_id: string;
    readonly name: string;
    readonly id: string;
    readonly tags: string[];
}
interface ValidationResult {
    readonly error_context: {
        readonly [_key: string]: string[];
    };
    readonly failed: boolean;
    readonly errors: {
        readonly [_key: string]: string[];
    };
}
/**
 * List all configuration variables
 */
export function listConfigurationVariables(): Promise<ConfigurationVariableArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.sidecar/sidecar/configuration_variables', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create new configuration variable
 */
export function createConfigurationVariable(JSONbody: ConfigurationVariable): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.sidecar/sidecar/configuration_variables', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Validate a configuration variable
 */
export function validateConfigurationVariable(JSONbody: ConfigurationVariable): Promise<ValidationResult> {
    return __request__('POST', '/plugins/org.graylog.plugins.sidecar/sidecar/configuration_variables/validate', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a configuration variable
 */
export function updateConfigurationVariable(id: string, JSONbody: ConfigurationVariable): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.sidecar/sidecar/configuration_variables/${id}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a configuration variable
 */
export function deleteConfigurationVariable(id: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.sidecar/sidecar/configuration_variables/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Show configurations using this variable
 */
export function getConfigurationVariablesConfigurations(id: string): Promise<ConfigurationArray> {
    return __request__('GET', `/plugins/org.graylog.plugins.sidecar/sidecar/configuration_variables/${id}/configurations`, null, {}, {
        'Accept': ['application/json']
    });
}
