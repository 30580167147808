import __request__ from 'routing/request';
interface OauthConfigResponse {
    readonly oauth_auth_url: string;
    readonly oauth_logout_url: string;
    readonly oauth_title: string;
    readonly oauth_type: string;
    readonly oauth_base_url: string;
    readonly oauth_callback_url: string;
    readonly oauth_client_id: string;
}
/**
 * If Okta or OIDC is active, then return config information required for authentication
 */
export function getConfig(): Promise<OauthConfigResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.security/oauth/authconfig', null, {}, {
        'Accept': ['application/json']
    });
}
