import * as React from 'react';
import styled from 'styled-components';

import type { EventNotification } from 'stores/event-notifications/EventNotificationsStore';
import { EmptyList, TD as DefaultTD, RowTitle } from 'security-app/components/common';
import { GLCheckbox } from 'common/components';

const TD = styled(DefaultTD)`
  padding: 8px 6px !important;
`;

type Props = {
  notificationOptions: EventNotification[],
  notificationId: string,
  setNotificationId: (notification: string) => void,
};

function ListRows({ notificationOptions, notificationId, setNotificationId }: Props) {
  const selectNotification = (newNotification: EventNotification) => () => {
    setNotificationId(newNotification.id);
  };

  return notificationOptions.length < 1 ? (
    <EmptyList noSearchResultText="No Notifications found for the provided criteria"
               noEntitiesExist="Currently there are no Notifications to list"
               colSpan={4} />
  ) : (
    <>
      {notificationOptions.map((notification: EventNotification) => (
        <tr key={notification.id}>
          <TD><GLCheckbox checked={notification.id === notificationId} onChange={selectNotification(notification)} /></TD>
          <TD>
            <RowTitle onClick={selectNotification(notification)}>
              {notification.title}
            </RowTitle>
          </TD>
          <TD>{notification.description}</TD>
          <TD>{notification.config?.type}</TD>
        </tr>
      ))}
    </>
  );
}

export default ListRows;
