import { useMutation, useQueryClient } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_WAREHOUSE_API_ROUTES } from 'data-warehouse/Constants';

export type DataWarehouseRetentionUpdateProps = {
  streamId: string,
  retentionTime: string
};

const onUpdateDataWarehouseRetention = ({
  streamId,
  retentionTime,
}: DataWarehouseRetentionUpdateProps) => fetch('POST', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.stream_retention(streamId).url), {
  retention_time: retentionTime,
});

const useDataWarehouseRetention = (): {
 onUpdateDataWarehouseRetentionMutation: (retrievalOperationDeleteProps: DataWarehouseRetentionUpdateProps) => Promise<void>,
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: onUpdateDataWarehouseRetentionMutation } = useMutation(onUpdateDataWarehouseRetention, {
    onSuccess: () => {
      queryClient.invalidateQueries(['data-warehouse-config']);

      UserNotification.success('Successfully updated Data Warehouse retention configuration.',
        'Update Data Warehouse retention configuration');
    },
    onError: (errorThrown) => {
      UserNotification.error(`Updating Data Warehouse retention configuration failed with status: ${errorThrown}`,
        'Could not update Data Warehouse retention configuration');
    },
  });

  return ({
    onUpdateDataWarehouseRetentionMutation,
  });
};

export default useDataWarehouseRetention;
