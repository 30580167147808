import __request__ from 'routing/request';
interface PipelineInterpreterTrace {
    readonly time: number;
    readonly message: string;
}
interface DecorationStats {
    readonly added_fields: {
        readonly [_key: string]: Object;
    };
    readonly changed_fields: {
        readonly [_key: string]: Object;
    };
    readonly removed_fields: {
        readonly [_key: string]: Object;
    };
}
interface SimulationRequest {
    readonly stream_id: string;
    readonly message: {
        readonly [_key: string]: Object;
    };
    readonly input_id: string;
}
interface ResultMessageSummary {
    readonly decoration_stats: DecorationStats;
    readonly index: string;
    readonly highlight_ranges: {
        readonly [_key: string]: unknown[];
    };
    readonly message: {
        readonly [_key: string]: Object;
    };
}
interface SimulationResponse {
    readonly simulation_trace: PipelineInterpreterTrace[];
    readonly messages: ResultMessageSummary[];
    readonly took_microseconds: number;
}
/**
 * Simulate the execution of the pipeline message processor
 */
export function simulate(simulation: SimulationRequest): Promise<SimulationResponse> {
    return __request__('POST', '/plugins/org.graylog.plugins.pipelineprocessor/system/pipelines/simulate', simulation, {}, {
        'Accept': ['application/json']
    });
}
