import __request__ from 'routing/request';
interface BulkActionsRequest {
    readonly collectors: BulkActionRequest[];
    readonly action: string;
}
interface BulkActionRequest {
    readonly sidecar_id: string;
    readonly collector_ids: string[];
}
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface CollectorStatus {
    readonly verbose_message: string;
    readonly collector_id: string;
    readonly message: string;
    readonly configuration_id: string;
    readonly status: number;
}
interface ConfigurationAssignment {
    readonly assigned_from_tags: string[];
    readonly collector_id: string;
    readonly configuration_id: string;
}
interface NodeLogFile {
    readonly path: string;
    readonly mod_time: string;
    readonly size: number;
    readonly is_dir: boolean;
}
interface SidecarSummary {
    readonly node_details: NodeDetails;
    readonly assignments: ConfigurationAssignment[];
    readonly collectors: string[];
    readonly last_seen: string;
    readonly sidecar_version: string;
    readonly node_name: string;
    readonly active: boolean;
    readonly node_id: string;
}
interface NodeMetrics {
    readonly cpu_idle: number;
    readonly disks_75: string[];
    readonly load_1: number;
}
interface CollectorStatusList {
    readonly collectors: CollectorStatus[];
    readonly message: string;
    readonly status: number;
}
interface NodeDetails {
    readonly ip: string;
    readonly collector_configuration_directory: string;
    readonly operating_system: string;
    readonly metrics: NodeMetrics;
    readonly log_file_list: NodeLogFile[];
    readonly status: CollectorStatusList;
    readonly tags: string[];
}
interface SidecarListResponse {
    readonly sidecars: SidecarSummary[];
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly query: string;
    readonly sort: string;
    readonly filters: {
        readonly [_key: string]: string;
    };
    readonly only_active: boolean;
    readonly order: 'asc' | 'desc';
}
interface AdministrationRequest {
    readonly per_page: number;
    readonly query: string;
    readonly page: number;
    readonly filters: {
        readonly [_key: string]: string;
    };
}
/**
 * Lists existing Sidecar registrations including compatible sidecars using pagination
 */
export function administration(JSONbody: AdministrationRequest): Promise<SidecarListResponse> {
    return __request__('POST', '/plugins/org.graylog.plugins.sidecar/sidecar/administration', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Set collector actions in bulk
 */
export function setAction(JSONbody: BulkActionsRequest): Promise<unknown> {
    return __request__('PUT', '/plugins/org.graylog.plugins.sidecar/sidecar/administration/action', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
