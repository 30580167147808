import __request__ from 'routing/request';
interface RemoteReindexIndex {
    readonly took: string;
    readonly error_msg: string;
    readonly created: string;
    readonly name: string;
    readonly progress: IndexMigrationProgress;
    readonly task_id: string;
    readonly completed: boolean;
    readonly status: 'NOT_STARTED' | 'RUNNING' | 'ERROR' | 'FINISHED';
}
interface RemoteReindexParams {
    readonly allowlist: string;
    readonly hostname: string;
    readonly password: string;
    readonly indices: string[];
    readonly synchronous: boolean;
    readonly threads_count: number;
    readonly trust_unknown_certs: boolean;
    readonly user: string;
}
interface IndexMigrationProgress {
    readonly total: number;
    readonly deleted: number;
    readonly noops: number;
    readonly created: number;
    readonly version_conflicts: number;
    readonly updated: number;
}
interface RemoteReindexMigration {
    readonly indices: RemoteReindexIndex[];
    readonly progress: number;
    readonly id: string;
    readonly error: string;
    readonly logs: LogEntry[];
    readonly tasks_progress: {
        readonly [_key: string]: number;
    };
    readonly status: 'NOT_STARTED' | 'RUNNING' | 'ERROR' | 'FINISHED';
}
interface LogEntry {
    readonly log_level: 'INFO' | 'WARN' | 'ERROR';
    readonly message: string;
    readonly timestamp: string;
}
/**
 * by remote reindexing
 */
export function migrate(remoteconfiguration?: RemoteReindexParams): Promise<string> {
    return __request__('POST', '/plugins/org.graylog.plugins.views/remote-reindex-migration/remoteReindex', remoteconfiguration, {}, {
        'Accept': ['application/json']
    });
}
/**
 * status
 */
export function status(migrationID?: string): Promise<RemoteReindexMigration> {
    return __request__('GET', `/plugins/org.graylog.plugins.views/remote-reindex-migration/status/${migrationID}`, null, {}, {
        'Accept': ['application/json']
    });
}
