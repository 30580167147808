import __request__ from 'routing/request';
interface SigmaRuleSummary {
    readonly execute_every_unit: string;
    readonly level: string;
    readonly query: string;
    readonly streams: string[];
    readonly cron_timezone: string;
    readonly last_run: string;
    readonly _scope: string;
    readonly source: string;
    readonly filters: UsedSearchFilter[];
    readonly remediation_steps: string;
    readonly title: string;
    readonly execute_every: number;
    readonly enabled: boolean;
    readonly stream_categories: string[];
    readonly search_within_unit: string;
    readonly updated_at: string;
    readonly cron_expression: string;
    readonly use_cron_scheduling: boolean;
    readonly event_definition_id: string;
    readonly id: string;
    readonly search_within: number;
    readonly notifications: string[];
    readonly status: string;
}
interface SigmaRuleFilterOptionsSummary {
    readonly level: string[];
    readonly _id: string;
    readonly enabled: boolean[];
    readonly status: string[];
}
interface SigmaRuleValidationResponse {
    readonly errors: string[];
}
interface UsedSearchFilter {
}
interface RuleRequestResponse {
    readonly event_procedure: string;
    readonly search_within_ms: number;
    readonly execute_every_ms: number;
    readonly streams: string[];
    readonly cron_timezone: string;
    readonly source: string;
    readonly filters: UsedSearchFilter[];
    readonly remediation_steps: string;
    readonly stream_categories: string[];
    readonly cron_expression: string;
    readonly use_cron_scheduling: boolean;
    readonly id: string;
    readonly notifications: string[];
}
interface BulkRuleRequest {
    readonly ids: string[];
}
interface RuleConfiguration {
    readonly stream_categories: string[];
    readonly search_within_ms: number;
    readonly execute_every_ms: number;
    readonly cron_expression: string;
    readonly use_cron_scheduling: boolean;
    readonly streams: string[];
    readonly cron_timezone: string;
    readonly id: string;
    readonly filters: UsedSearchFilter[];
    readonly remediation_steps: string;
    readonly notifications: string[];
}
interface FilterOptionsRequest {
    readonly fields: string[];
}
interface SigmaRuleZipValidationResponse {
    readonly errors: FileErrors[];
}
interface FileErrors {
    readonly file_name: string;
    readonly errors: string[];
}
interface BulkConfigUpdateRequest {
    readonly ids: string[];
    readonly notifications: string[];
}
interface PaginatedResponse_SigmaRuleSummary {
    readonly [_key: string]: Object;
}
interface RuleImportRequest {
    readonly rules: RuleConfiguration[];
}
type stringArray = string[];
/**
 * Get paginated Sigma Rules
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function list(sort: 'parsed_rule.title' | 'parsed_rule.level' | 'parsed_rule.status' | 'last_run' | 'updated_at' | 'enabled' = 'parsed_rule.title', page: number = 1, per_page: number = 15, query?: string, filters?: string, user_timezone: string = 'UTC', direction: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_SigmaRuleSummary> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/sigma/rules', null, { 'page': page, 'per_page': per_page, 'query': query, 'filters': filters, 'user_timezone': user_timezone, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a Sigma Rule
 */
export function create(JSONBody?: RuleRequestResponse): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/sigma/rules', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Bulk add notifications to Sigma Rules
 */
export function bulkConfigurationUpdate(JSONBody?: BulkConfigUpdateRequest): Promise<unknown> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/sigma/rules/bulk/add_notifications', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Bulk delete Sigma Rules
 */
export function deleteBulk(JSONBody?: BulkRuleRequest): Promise<unknown> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/sigma/rules/bulk/delete', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Bulk disable Sigma Rules
 */
export function disableBulk(JSONBody?: BulkRuleRequest): Promise<unknown> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/sigma/rules/bulk/disable', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Bulk enable Sigma Rules
 */
export function enableBulk(JSONBody?: BulkRuleRequest): Promise<unknown> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/sigma/rules/bulk/enable', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Downloads the requested rules
 * @param ruleIds The sigma rule ids.
 */
export function download(ruleIds: stringArray): Promise<unknown> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/sigma/rules/download', null, { 'ruleIds': ruleIds }, {
        'Accept': ['application/octet-stream']
    });
}
/**
 * Get all possible values for a list of fields
 */
export function getFilterOptions(JSONBody?: FilterOptionsRequest): Promise<SigmaRuleFilterOptionsSummary> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/sigma/rules/filter_options', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Import Sigma rules from Github
 */
export function importRules(JSONBody?: RuleImportRequest): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/sigma/rules/import', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Upload sigma rules
 */
export function upload(): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/sigma/rules/upload', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Validate a Sigma Rule
 */
export function validate(JSONBody?: string): Promise<SigmaRuleValidationResponse> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/sigma/rules/validate', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Validate a zip file containing Sigma rules
 */
export function validateZip(): Promise<SigmaRuleZipValidationResponse> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/sigma/rules/validate_zip', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a Sigma Rule
 */
export function get(id: string): Promise<SigmaRuleSummary> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/sigma/rules/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a Sigma Rule
 */
export function update(id: string, JSONBody?: RuleRequestResponse): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/sigma/rules/${id}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a Sigma Rule
 */
export function remove(id: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/sigma/rules/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Disables a sigma rule
 */
export function disable(id: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/sigma/rules/${id}/disable`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Enables a sigma rule
 */
export function enable(id: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/sigma/rules/${id}/enable`, null, {}, {
        'Accept': ['application/json']
    });
}
