import __request__ from 'routing/request';
/**
 * Add a category to a stream
 */
export function assignCategoryToStream(streamId: string, category: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.illuminate/stream_categories/add/${streamId}/${category}`, null, {}, {
        'Accept': ['text/plain']
    });
}
/**
 * Delete a category from a stream
 */
export function deleteCategoryFromStream(streamId: string, category: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.illuminate/stream_categories/delete/${streamId}/${category}`, null, {}, {
        'Accept': ['text/plain']
    });
}
