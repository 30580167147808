import __request__ from 'routing/request';
/**
 * Get a list of investigation statuses
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function listStatuses(sort: 'status' = 'status', page: number = 1, per_page: number = 15, query?: string, direction: 'asc' | 'desc' = 'asc'): Promise<unknown[]> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations/statuses', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new investigation status
 */
export function create(Status?: string): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/investigations/statuses', Status, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove default investigation status
 */
export function removeDefault(): Promise<unknown> {
    return __request__('DELETE', '/plugins/org.graylog.plugins.securityapp/investigations/statuses/default', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an investigation status
 */
export function update(id: string, Status?: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/investigations/statuses/${id}`, Status, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an investigation status
 */
export function remove(id: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/investigations/statuses/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Set default investigation status
 */
export function setDefault(id?: string): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/investigations/statuses/${id}/default`, null, {}, {
        'Accept': ['application/json']
    });
}
