import UserNotification from 'util/UserNotification';

// eslint-disable-next-line import/prefer-default-export
export const handleFailures = (failures: Array<{ entity_id: string }> = [], actionPastTense: string = 'submitted') => {
  if (failures.length) {
    UserNotification.error(`Data Warehouse could not be ${actionPastTense}.`);
  } else {
    UserNotification.success(`Data Warehouse was ${actionPastTense} successfully.`, 'Success');
  }
};
