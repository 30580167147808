import type { ColumnFilterData, FilterData, IlluminateHubFilterData } from './ColumnFilter.types';

export function stringifyFilters(filters: ColumnFilterData) {
  if (!filters || Object.keys(filters).length === 0) return '';

  const fDataByType = (fData: FilterData[]) => {
    const strFData = fData.map(({ value }: { value: string }) => value).join(',');

    return fData[0].type === 'string' ? `'${strFData}'` : strFData;
  };

  return Object.entries(filters)
    .map(([column, fData]: [string, FilterData[]]) => `${column}:${fDataByType(fData)}`)
    .join(';');
}

function isDate(value: string) {
  return !Number.isNaN(Date.parse(value));
}

function buildDateQuery(from: string, to: string) {
  const fromDate = new Date(from).toISOString().replace('T', ' ').replace(/Z$/, '');
  const toDate = to
    ? new Date(to).toISOString().replace('T', ' ').replace(/Z$/, '')
    : new Date(new Date(from).getTime() + 24 * 60 * 60 * 1000).toISOString().replace('T', ' ').replace(/Z$/, '');

  return `["${fromDate}" TO "${toDate}"]`;
}

export function buildOpenSearchQuery(filters: ColumnFilterData) {
  const query = Object.entries(filters)
    .map(([column, fData]: [string, FilterData[]]) => {
      const strFData = fData.every(({ value, type }) => isDate(value) && type === 'date')
        ? buildDateQuery(fData[0].value, fData[1]?.value)
        : `(${fData.map(({ value, type }: { value: string, type: string }) => (type === 'number' ? `${value}` : `"${value}"`)).join(' OR ')})`;

      return `${column}:${strFData}`;
    })
    .join(' AND ');

  return query;
}

export function buildIlluminateQuery(filters: IlluminateHubFilterData) {
  if (!filters || Object.keys(filters).length === 0) return '';

  const fDataFormatted = (fData: string[]) => {
    const strFData = fData.map((value) => value).join(',');

    return `${strFData}`;
  };

  return Object.entries(filters)
    .map(([key, fData]: [string, string[]]) => `${key}=${fDataFormatted(fData)}`)
    .join('&');
}
