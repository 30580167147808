import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface SearchFilter {
    readonly description: string;
    readonly id: string;
    readonly title: string;
}
interface SearchFilterPageListResponse {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly query: string;
    readonly sort: string;
    readonly filters: SearchFilter[];
    readonly order: string;
}
type SearchFilterArray = SearchFilter[];
/**
 * Create a search filter
 */
export function create(SearchfilterinJSONformat: SearchFilter): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.searchfilters/search_filters', SearchfilterinJSONformat, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a list of available search filters
 */
export function getAll(): Promise<SearchFilterArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.searchfilters/search_filters', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of search filters
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getPage(sort: 'title' = 'title', page: number = 1, per_page: number = 50, query?: string, scope: 'ALL' | 'OWNED' = 'ALL', order: 'asc' | 'desc' = 'asc'): Promise<SearchFilterPageListResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.searchfilters/search_filters/paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'scope': scope, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single search filter
 */
export function get(filterId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.searchfilters/search_filters/${filterId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a search filter
 */
export function update(filterId: string, SearchfilterinJSONformat: SearchFilter): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.searchfilters/search_filters/${filterId}`, SearchfilterinJSONformat, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a search filter
 */
export function remove(filterId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.searchfilters/search_filters/${filterId}`, null, {}, {
        'Accept': ['application/json']
    });
}
