import * as React from 'react';

import { Icon } from 'components/common';
import { GLCheckbox } from 'common/components';
import type { GitRepoContent, GitRepoAPIModel } from 'security-app/hooks/api/sigmaAPI.types';

import {
  Dir,
  File,
  InfoButton,
  RuleDescription,
} from './ImportRepoRulesModal.styles';
import type { FormRuleAction } from './ImportRepoRulesModal';
import type { FormRule } from './SelectedRuleForm/SelectedRuleForm';

type Props = {
  item: GitRepoContent & GitRepoAPIModel,
  repoPath: string,
  setRepo: (newRepo: GitRepoAPIModel) => void,
  setRepoPath: (newPath: string) => void,
  formRules: FormRule[],
  formRulesDispatch: (action: FormRuleAction) => void,
};

const RepoListItem = ({
  item,
  repoPath,
  setRepo,
  setRepoPath,
  formRules,
  formRulesDispatch,
}: Props) => {
  const [mousePos, setMousePos] = React.useState<{x: number, y: number}>({ x: 0, y: 0 });
  const [showDescription, setShowDescription] = React.useState<boolean>(false);

  const loadRepoRules = () => {
    setRepo(item);
    setRepoPath('');
  };

  const loadDirectory = () => {
    setRepoPath(`${repoPath}/${item.name}`);
  };

  const selectRule = (event: React.BaseSyntheticEvent) => {
    if (event.target.checked) {
      formRulesDispatch({ type: 'add', payload: item });
    } else {
      formRulesDispatch({ type: 'remove', payload: item });
    }
  };

  const isRuleSelected = (inRule: GitRepoContent) => (
    !!formRules.find((formRule: FormRule) => formRule.id === inRule.id)
  );

  const handleMouseEnter = (event: React.MouseEvent) => {
    const { x, y } = (event.target as HTMLElement).getBoundingClientRect();
    setMousePos({ x: x + 8, y: y + 8 });
    setShowDescription(true);
  };

  if (!item.type) {
    return (
      <Dir onClick={loadRepoRules} data-testid="repo">
        {item.name}
      </Dir>
    );
  }

  if (item.type === 'dir') {
    return (
      <Dir title={item.name} onClick={loadDirectory} data-testid="directory">
        {item.name}/
      </Dir>
    );
  }

  return (
    <>
      <File htmlFor={`import-repo-file-${item.id}`} data-testid="file">
        <GLCheckbox id={`import-repo-file-${item.id}`}
                    checked={isRuleSelected(item)}
                    onChange={selectRule}
                    data-testid="select-file" />
        <InfoButton data-testid="info-button"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={() => setShowDescription(false)}>
          <Icon style={{ cursor: 'pointer' }} name="help" size="sm" />
        </InfoButton>
        {item.title}
      </File>
      {showDescription && (
        <RuleDescription $mousePos={mousePos}
                         className={showDescription ? 'show' : ''}
                         data-testid="rule-description">
          <p><b>File Name</b>: {item.name}</p>
          <p><b>Description</b>: {item.rule_description}</p>
          {!!item.product && (<p><b>Product</b>: {item.product}</p>)}
          {!!item.tags && (<p><b>Tags</b>: {item.tags.split(' ').join(', ')}</p>)}
        </RuleDescription>
      )}
    </>
  );
};

export default RepoListItem;
