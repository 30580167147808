import __request__ from 'routing/request';
interface ForwarderInputDto {
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly created_at: string;
    readonly id: string;
    readonly title: string;
    readonly input_profile_id: string;
    readonly type: string;
    readonly version: number;
}
/**
 * Get Forwarder Input by Id
 */
export function getById(inputId: string): Promise<ForwarderInputDto> {
    return __request__('GET', `/plugins/org.graylog.plugins.forwarder/forwarder/input/${inputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
