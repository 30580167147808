import __request__ from 'routing/request';
interface InvestigationsConfig {
    readonly enable_assignment_notifications: boolean;
    readonly default_priority: string;
    readonly default_status: string;
}
/**
 * Get investigations configuration
 */
export function getInvestigation(): Promise<InvestigationsConfig> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/investigations/config', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update investigation configuration.
 */
export function updateConfiguration(notificationsEnabled: boolean): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/investigations/config/notifications/${notificationsEnabled}`, null, {}, {
        'Accept': ['application/json']
    });
}
