import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface EntityAttribute {
    readonly related_property: string;
    readonly filterable: boolean;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'DOUBLE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
interface SearchFilterUsageSummary {
    readonly summary: SearchFilterUsagePerType[];
}
interface SearchFilterUsagePerType {
    readonly usages: number;
    readonly type: 'SEARCH' | 'DASHBOARD' | 'DASHBOARD_WIDGET' | 'EVENT_DEFINITION' | 'SIGMA_RULE';
}
interface PageListResponse_SearchFilterUsage {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: SearchFilterUsage[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
interface SearchFilterUsage {
    readonly owner: string;
    readonly id: string;
    readonly type: 'SEARCH' | 'DASHBOARD' | 'DASHBOARD_WIDGET' | 'EVENT_DEFINITION' | 'SIGMA_RULE';
    readonly title: string;
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
/**
 * Get a usage details for a single search filter
 * @param type The type of search filter usage
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getPage(filterId: string, page: number = 1, per_page: number = 50, query?: string, type: 'SEARCH' | 'DASHBOARD' | 'DASHBOARD_WIDGET' | 'EVENT_DEFINITION' | 'SIGMA_RULE' = 'SEARCH', sort: 'title' | 'type' | '_id' | 'id' = 'title', order: 'asc' | 'desc' = 'asc'): Promise<PageListResponse_SearchFilterUsage> {
    return __request__('GET', `/plugins/org.graylog.plugins.searchfilters/search_filters_usage/details/${filterId}/paginated`, null, { 'page': page, 'per_page': per_page, 'query': query, 'type': type, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Get a usage summary for a single search filter
 */
export function get(filterId: string): Promise<SearchFilterUsageSummary> {
    return __request__('GET', `/plugins/org.graylog.plugins.searchfilters/search_filters_usage/summary/${filterId}`, null, {}, {
        'Accept': ['application/json']
    });
}
