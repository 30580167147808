import __request__ from 'routing/request';
interface Binding {
    readonly type: string;
}
interface TimeRange {
    readonly type: string;
}
interface ExecutionState {
    readonly parameter_bindings: {
        readonly [_key: string]: Binding;
    };
    readonly global_override: ExecutionStateGlobalOverride;
    readonly additional_parameters: {
        readonly [_key: string]: Object;
    };
    readonly cancel_after_seconds: number;
    readonly queries: {
        readonly [_key: string]: ExecutionStateGlobalOverride;
    };
}
interface SearchTypeExecutionState {
    readonly per_page: number;
    readonly offset: number;
    readonly limit: number;
    readonly page: number;
    readonly after: unknown[];
}
interface FieldValuesRequest {
    readonly parameter: string;
    readonly executionState: ExecutionState;
}
type anyArray = unknown[];
interface BackendQuery {
}
interface ExecutionStateGlobalOverride {
    readonly timerange: TimeRange;
    readonly keep_search_types: string[];
    readonly offset: number;
    readonly query: BackendQuery;
    readonly now: string;
    readonly limit: number;
    readonly search_types: {
        readonly [_key: string]: SearchTypeExecutionState;
    };
    readonly keep_queries: string[];
}
/**
 * Get a list of all values of a given field
 */
export function fieldValues(JSONbody: FieldValuesRequest, searchId?: string): Promise<anyArray> {
    return __request__('POST', `/plugins/org.graylog.plugins.enterprise/fieldvalues/${searchId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
