import * as React from 'react';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';

import Job from 'data-warehouse/components/Job';
import { Row, Col } from 'components/bootstrap';
import useDataWarehouseJobs from 'data-warehouse/hooks/useDataWarehouseJobs';

type Props = {
  streamId?: string,
}
const StyledH2 = styled.h2(({ theme }) => css`
  margin-bottom: ${theme.spacings.xs};
`);

const DataWarehouseJobs = ({ streamId = null }: Props) => {
  const {
    dataWarehouseData:
      {
        inProgressCount,
        jobs,
      },
    isLoadingDataWarehouse,
    isError,
    onCancelDataWarehouseJob,
    onAcknowledgeDataWarehouseJob,
  } = useDataWarehouseJobs(streamId);
  const params = useParams<{
    archiveId?: string
  }>();
  const { archiveId } = params;

  if (isLoadingDataWarehouse || isError || inProgressCount === 0 || !jobs) {
    return null;
  }

  const archivingJobs = jobs.filter((j) => {
    if (!archiveId) {
      return true;
    }

    return j.archive.join(',').includes(archiveId);
  }).map((job) => (
    <Job key={job.id}
         job={job}
         onCancel={onCancelDataWarehouseJob}
         onAcknowledge={onAcknowledgeDataWarehouseJob} />
  ));

  return (
    <Row className="content">
      <Col md={12}>
        <StyledH2>Data Warehouse Jobs</StyledH2>
        {archivingJobs}
      </Col>
    </Row>
  );
};

export default DataWarehouseJobs;
