import __request__ from 'routing/request';
interface JournalEstimate {
    readonly bytes_per_minute: number;
    readonly journal_size: number;
    readonly max_downtime_minutes: number;
}
interface MigrationStepRequest {
    readonly args: {
        readonly [_key: string]: Object;
    };
    readonly step: 'SELECT_MIGRATION' | 'SHOW_CA_CREATION' | 'SHOW_RENEWAL_POLICY_CREATION' | 'SHOW_MIGRATION_SELECTION' | 'PROVISION_DATANODE_CERTIFICATES' | 'SHOW_DATA_MIGRATION_QUESTION' | 'SHOW_MIGRATE_EXISTING_DATA' | 'CHECK_REMOTE_INDEXER_CONNECTION' | 'START_REMOTE_REINDEX_MIGRATION' | 'RETRY_MIGRATE_EXISTING_DATA' | 'REQUEST_MIGRATION_STATUS' | 'SHOW_ASK_TO_SHUTDOWN_OLD_CLUSTER' | 'SHOW_PROVISION_ROLLING_UPGRADE_NODES_WITH_CERTIFICATES' | 'SHOW_STOP_PROCESSING_PAGE' | 'SELECT_ROLLING_UPGRADE_MIGRATION' | 'SELECT_REMOTE_REINDEX_MIGRATION' | 'DISCOVER_NEW_DATANODES' | 'SKIP_EXISTING_DATA_MIGRATION' | 'RUN_DIRECTORY_COMPATIBILITY_CHECK' | 'CALCULATE_JOURNAL_SIZE' | 'CONFIRM_OLD_CLUSTER_STOPPED' | 'CONFIRM_OLD_CONNECTION_STRING_FROM_CONFIG_REMOVED_AND_GRAYLOG_RESTARTED' | 'CONFIRM_OLD_CONNECTION_STRING_FROM_CONFIG_REMOVED' | 'SHOW_ROLLING_UPGRADE_ASK_TO_SHUTDOWN_OLD_CLUSTER';
}
interface CurrentStateInformation {
    readonly error_message: string;
    readonly response: Object;
    readonly state: 'NEW' | 'MIGRATION_WELCOME_PAGE' | 'CA_CREATION_PAGE' | 'RENEWAL_POLICY_CREATION_PAGE' | 'MIGRATION_SELECTION_PAGE' | 'ROLLING_UPGRADE_MIGRATION_WELCOME_PAGE' | 'REMOTE_REINDEX_WELCOME_PAGE' | 'PROVISION_DATANODE_CERTIFICATES_RUNNING' | 'EXISTING_DATA_MIGRATION_QUESTION_PAGE' | 'MIGRATE_EXISTING_DATA' | 'REMOTE_REINDEX_RUNNING' | 'ASK_TO_SHUTDOWN_OLD_CLUSTER' | 'PROVISION_ROLLING_UPGRADE_NODES_RUNNING' | 'JOURNAL_SIZE_DOWNTIME_WARNING' | 'MESSAGE_PROCESSING_STOP' | 'RESTART_GRAYLOG' | 'FAILED' | 'DIRECTORY_COMPATIBILITY_CHECK_PAGE' | 'FINISHED';
    readonly next_steps: ('SELECT_MIGRATION' | 'SHOW_CA_CREATION' | 'SHOW_RENEWAL_POLICY_CREATION' | 'SHOW_MIGRATION_SELECTION' | 'PROVISION_DATANODE_CERTIFICATES' | 'SHOW_DATA_MIGRATION_QUESTION' | 'SHOW_MIGRATE_EXISTING_DATA' | 'CHECK_REMOTE_INDEXER_CONNECTION' | 'START_REMOTE_REINDEX_MIGRATION' | 'RETRY_MIGRATE_EXISTING_DATA' | 'REQUEST_MIGRATION_STATUS' | 'SHOW_ASK_TO_SHUTDOWN_OLD_CLUSTER' | 'SHOW_PROVISION_ROLLING_UPGRADE_NODES_WITH_CERTIFICATES' | 'SHOW_STOP_PROCESSING_PAGE' | 'SELECT_ROLLING_UPGRADE_MIGRATION' | 'SELECT_REMOTE_REINDEX_MIGRATION' | 'DISCOVER_NEW_DATANODES' | 'SKIP_EXISTING_DATA_MIGRATION' | 'RUN_DIRECTORY_COMPATIBILITY_CHECK' | 'CALCULATE_JOURNAL_SIZE' | 'CONFIRM_OLD_CLUSTER_STOPPED' | 'CONFIRM_OLD_CONNECTION_STRING_FROM_CONFIG_REMOVED_AND_GRAYLOG_RESTARTED' | 'CONFIRM_OLD_CONNECTION_STRING_FROM_CONFIG_REMOVED' | 'SHOW_ROLLING_UPGRADE_ASK_TO_SHUTDOWN_OLD_CLUSTER')[];
}
/**
 * Get journal size estimate (bytes/minute)
 */
export function getJournalEstimate(): Promise<JournalEstimate> {
    return __request__('GET', '/plugins/org.graylog.plugins.views/migration/journalestimate', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Serialize
 */
export function serialize(): Promise<string> {
    return __request__('GET', '/plugins/org.graylog.plugins.views/migration/serialize', null, {}, {
        'Accept': ['text/plain']
    });
}
/**
 * Migration status
 */
export function status(): Promise<CurrentStateInformation> {
    return __request__('GET', '/plugins/org.graylog.plugins.views/migration/state', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Reset the whole migration to the first step, start over
 */
export function resetState(): Promise<CurrentStateInformation> {
    return __request__('DELETE', '/plugins/org.graylog.plugins.views/migration/state', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * trigger migration step
 */
export function trigger(request?: MigrationStepRequest): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.views/migration/trigger', request, {}, {
        'Accept': ['application/json']
    });
}
