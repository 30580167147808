import * as React from 'react';
import { useState } from 'react';
import capitalize from 'lodash/capitalize';
import { useQueryClient } from '@tanstack/react-query';

import type { StreamDataWarehouseConfig } from 'data-warehouse/Types';
import useStreamDataWarehouseStatusMutation from 'data-warehouse/hooks/useStreamDataWarehouseStatusMutation';
import { ConfirmDialog, OverlayTrigger } from 'components/common';
import { Link } from 'components/common/router';
import Popover from 'components/common/Popover';
import {
  useDataWarehouseBackendConfig,
} from 'data-warehouse/data-warehouse-backend-config/hooks/useDataWarehouseBackendConfig';
import DestinationSwitch from 'components/streams/StreamDetails/routing-destination/DestinationSwitch';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import DataWarehouseRoutes from 'data-warehouse/Routes';

type Props = {
  streamId: string,
  config: StreamDataWarehouseConfig,
}

const StreamDataWarehouseEnableButton = ({ streamId, config }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { onToggleStreamDataWarehouseConfigStatus } = useStreamDataWarehouseStatusMutation();
  const { data, isSuccess } = useDataWarehouseBackendConfig();
  const isDataWareHouseEnabled = config?.enabled || false;
  const action = isDataWareHouseEnabled ? 'disable' : 'enable';
  const title = isDataWareHouseEnabled ? 'Enabled' : 'Disabled';
  const activeBackendExists = isSuccess && data.active_backend;
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();
  const sendTelemetry = useSendTelemetry();

  const onModalClose = () => {
    setShowModal(false);
  };

  const onButtonClick = () => {
    setShowModal(true);

    sendTelemetry(TELEMETRY_EVENT_TYPE.DATAWAREHOUSE.ENABLE_DATAWAREHOUSE_CLICKED, {
      app_pathname: 'datawarehouse',
    });
  };

  const onConfirm = () => {
    onToggleStreamDataWarehouseConfigStatus({ streamIds: [streamId], enabled: !isDataWareHouseEnabled }).then(() => {
      setShowModal(false);
      queryClient.invalidateQueries(['data-warehouse', streamId]);
    });
  };

  const popover = (
    <Popover>
      No active Data Warehouse backend configured.
      Go to Data <Link to={DataWarehouseRoutes.BACKEND}>Enterprise/Data Warehouse</Link> and set one up to use Data Warehouse.
    </Popover>
  );

  return (
    <>
      {showModal && (
        <ConfirmDialog show={showModal}
                       onConfirm={onConfirm}
                       onCancel={onModalClose}
                       title={`${capitalize(action)} Data Warehouse.`}>
          <>
            You are about to {`${action}`} <strong>Data Warehouse</strong> on this stream.
            <p>Are you sure you want to continue?</p>
          </>
        </ConfirmDialog>
      )}
      {activeBackendExists
        ? (
          <DestinationSwitch aria-label="Toggle data warehouse"
                             name="toggle-datawarehouse"
                             checked={isDataWareHouseEnabled}
                             onChange={onButtonClick}
                             disabled={!isPermitted(currentUser.permissions, 'archive:read')}
                             label={title} />
        )
        : (
          <OverlayTrigger placement="top"
                          trigger="click"
                          rootClose
                          overlay={popover}>
            <DestinationSwitch aria-label="Toggle data warehouse"
                               name="toggle-datawarehouse"
                               disabled
                               onChange={() => {}}
                               checked={false}
                               label={title} />
          </OverlayTrigger>
        )}
    </>
  );
};

export default StreamDataWarehouseEnableButton;
