import * as React from 'react';
import styled from 'styled-components';
import { useContext } from 'react';

import { ControlLabel, Button } from 'components/bootstrap';
import { MarkdownPreview } from 'components/common/MarkdownEditor';
import { Markdown } from 'components/common';
import { useGetEventDefinitionDetailsBatched } from 'security-app/hooks/useSecurityEventsAPI';
import { useModalContext } from 'security-app/components/common/Modal/ModalContext';
import type { SecurityEventAPIType } from 'security-app/hooks/api/securityEventsAPI.types';
import type { EventDefinition } from 'components/event-definitions/event-definitions-types';
import useCurrentUser from 'hooks/useCurrentUser';
import EventDetailsContext from 'security-app/components/SecurityEvents/Alerts/EventDetails/EventDetailsContext';
import { TD } from 'security-app/components/common';
import type { Attributes } from 'stores/PaginationTypes';
import SecurityEventDetailsTable
  from 'security-app/components/SecurityEvents/Alerts/EventDetails/SecurityEventDetailsTable';

import AssociatedAssetInformation from './AssociatedAssetInformation';

type SigmaEventDefinitionConfig = EventDefinition['config'] & {
  sigma_rule_id: string,
};

export const LEFT_COLUMN_WIDTH = '52%';
export const RIGHT_COLUMN_WIDTH = '48%';

const Row = styled.div<{ $gap?: string, $justify?: string, $align?: string, $orientation?: 'horz' | 'vert' }>`
  display: flex;
  flex-direction: ${({ $orientation }) => ($orientation === 'vert' ? 'column' : 'row')};
  justify-content: ${({ $justify }) => ($justify || 'flex-start')};
  align-items: ${({ $align }) => ($align || 'stretch')};
  gap: ${({ $gap }) => ($gap || '0.5rem')};
`;

const Col = styled.div<{ $gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => ($gap || '0.5rem')};
`;

const DetailsBox = styled(Col)`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.input.border};
`;

const StyledControlLabel = styled(ControlLabel)`
  margin: 0;
`;

type Props = {
  securityEvent: SecurityEventAPIType,
  orientation?: 'horz' | 'vert',
  detailsAttributes: Attributes,
};

function EventDetails({ securityEvent, orientation = 'horz', detailsAttributes }: Props) {
  const { permissions } = useCurrentUser();
  const { setModal, setEntity } = useModalContext();
  const eventDetailsContext = useContext(EventDetailsContext);
  const { eventDefinition } = useGetEventDefinitionDetailsBatched(securityEvent?.event?.event_definition_id);

  const canManageSecurityEvents = React.useMemo(() => (
    permissions.includes('security_event:edit') || permissions.includes('*')
  ), [permissions]);

  const openMDEditor = () => {
    setEntity(securityEvent);
    setModal('NOTES-EDITOR');
  };

  const editEventDefinition = () => {
    if (eventDefinition.config.type === 'sigma-v1') {
      setEntity((eventDefinition.config as SigmaEventDefinitionConfig).sigma_rule_id);
      setModal('EDIT-SIGMA-EVENT-DEFINITION');
    } else {
      setEntity(eventDefinition);
      setModal('EVENT-DEFINITION');
    }
  };

  const { title: eventDefinitionTitle, remediation_steps } = eventDefinition || { remediation_steps: '', title: securityEvent.event.event_definition_id };

  return (
    <>
      {securityEvent.event.associated_assets.length > 0 && (
        <Row>
          <StyledControlLabel>Associated Assets:</StyledControlLabel>
          <AssociatedAssetInformation identifiers={securityEvent.event.associated_assets} />
        </Row>
      )}
      <Row $orientation={orientation}>
        <Col style={{ width: orientation === 'vert' ? '100%' : LEFT_COLUMN_WIDTH }}>
          {eventDefinition && (
            <DetailsBox>
              <Row $justify={eventDetailsContext.hideEventDefinitionEdit ? 'flex-start' : 'space-between'}>
                <strong>Event Definition</strong>
                {(canManageSecurityEvents && !eventDetailsContext.hideEventDefinitionEdit) ? (
                  <Button bsSize="small" bsStyle="success" onClick={editEventDefinition}>Edit</Button>
                ) : <TD />}
              </Row>
              <span>{eventDefinitionTitle}</span>
            </DetailsBox>
          )}
          <DetailsBox style={{ flexGrow: 1 }}>
            <Row $justify={eventDetailsContext.hideNotesEdit ? 'flex-start' : 'space-between'}>
              <strong>Notes</strong>
              {(canManageSecurityEvents && !eventDetailsContext.hideNotesEdit) ? (
                <Button bsSize="small"
                        bsStyle="success"
                        onClick={openMDEditor}>{!securityEvent.notes ? 'Add' : 'Edit'}
                </Button>
              ) : <TD />}
            </Row>
            <Markdown text={securityEvent.notes || '_No notes_'} />
          </DetailsBox>
          <DetailsBox style={{ flexGrow: 2 }}>
            <strong>Remediation Steps</strong>
            <MarkdownPreview show
                             withFullView
                             noBorder
                             noBackground
                             value={remediation_steps || '_No remediation steps_'} />
          </DetailsBox>
        </Col>
        <Col style={{ width: orientation === 'vert' ? '100%' : RIGHT_COLUMN_WIDTH }}>
          <DetailsBox style={{ flexGrow: 1 }}>
            <strong>Details</strong>
            <SecurityEventDetailsTable event={securityEvent} attributesList={detailsAttributes} />
          </DetailsBox>
        </Col>
      </Row>
    </>
  );
}

export default EventDetails;
